@import "../../../../../../styles/global.main.scss";

.schedules-days {
  width: 100%;
  input[type="checkbox"] {
    position: relative;
    display: none;
    & + label {
      border: solid 1px $placeholderGray;
      border-radius: 8px;
      width: 100%;
      padding: $spacingXS $spacingXS $spacingXS $spacingXL;
      position: relative;
      i {
        background-image: url(../../../../../../assets/images/schedule-unchecked.png);
        background-repeat: no-repeat;
        width: 41px;
        height: 40px;
        position: absolute;
        top: 20px;
        left: 20px;
      }
      span {
        display: block;
        padding: $spacingXXS 0;
        font-weight: bold;
        font-size: $fontSize16;
      }
      .disabled {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.3);
      }
    }

    &:checked {
      & + label {
        border-color: $primaryBlue;
        i {
          background-image: url(../../../../../../assets/images/schedule-checked.png);
        }
        span {
          display: block;
          padding: $spacingXXS 0;
          color: $primaryBlue;
        }
        .disabled {
          display: none;
        }
      }
    }
  }
}
