@import "../../../../styles/global.main.scss";
.tab-content {
  .tab {
    .success-title {
      font-size: $fontSize40;
      color: $primaryBlue;
      font-weight: 700;
      display: block;
      margin-bottom: $spacingL;
    }
    .success-message {
      font-size: $fontSize20;
      display: block;
      margin-bottom: $spacingM;
      &.p-left {
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
        }
      }
    }
  }
}
