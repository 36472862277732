@import "../../../../../styles/global.main.scss";
.client-review-popup {
  .review-details {
    @mixin primary-span() {
      font-size: $fontBaseSize;
      color: $primaryBlue;
      font-weight: bold;
      margin-bottom: $spacingXXXS;
      padding-top: $spacingXXS;
      display: block;
    }
    display: grid;
    grid-template-columns: 245px auto;
    & > div:first-child {
      border-right: 1px solid $placeholderGray;
      margin-right: $spacingS;
    }
    div {
      h4 {
        font-size: $fontSize16;
        padding-bottom: $spacingXS;
        small {
          float: right;
        }
      }
      figure {
        img {
          max-width: 81px;
          max-height: 81px;
        }
      }
      .client-details {
        span {
          @include primary-span();
        }
      }
      .review-wrapper {
        display: grid;
        grid-template-columns: 225px auto;
        strong {
          @include primary-span();
          padding-bottom: $spacingXXXS;
        }
        .react-stars {
          margin-top: -$spacingXXXS;
        }
      }
    }
  }
  .bottom {
    display: grid;
    grid-template-columns: auto 160px;
    align-items: center;
    justify-content: space-between;
    margin-top: $spacingS;
    a {
      font-weight: bold;
    }
  }
}
