@import "../../../../styles/global.main.scss";
.error-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 70vh;
  span {
    font-size: 12rem;
    display: block;
    width: 100%;
    text-align: center;
    max-height: 100px;
    font-weight: 600;
    small {
      display: block;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
