@import "../../../../styles/global.main.scss";
.plans {
  &#payment {
    text-align: left;
  }
  .plan-title {
    display: block;
    margin-bottom: $spacingXXS;
    h3 {
      font-weight: bold;
      font-size: $fontSize20;
      margin: $spacingXS 0 $spacingXS 0;
    }
    .period-selector {
      margin-bottom: $spacingXS;
      width: 300px;
      margin: 0 auto;
    }
  }
  .payment-plans {
    border-radius: 1rem;
    background: $backgroundColorGray;
    border: 1px solid $placeholderGray;
    width: 100%;
    max-width: 1220px;
    max-height: 850px;
    margin-top: $spacingXXL;
    ul.features {
      li {
        padding: $spacingXXXS 0;
        display: block;
        font-weight: bold;
        color: $fontColorBlack;
        border-bottom: 1px solid $placeholderGray;
      }
    }
    .plan-type {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: center;
      justify-content: flex-start;
      width: 100%;
      margin: 0 auto 3em;
      top: -50px;
      border-radius: 16px;

      div {
        position: relative;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: stretch;
        align-items: stretch;
        text-align: center;
        -webkit-flex: 0 1 19.5%;
        flex: 0 1 19.5%;
        padding: $spacingXS 0;
        text-align: center;
        margin-top: -20px;
        background: $generalColorWhite;
        border: 1px solid #ddd;
        &:nth-child(1) {
          border: 0 !important;
          margin-top: 22px;
          background-color: transparent;
          text-align: left;
          padding-left: 3rem;
          ul {
            li {
              text-align: left;
            }
          }
        }
        &:nth-child(2) {
          border-radius: 1rem 0 0 1rem;
          border-right: 0;
        }
        &.active {
          border: 3px solid $primaryBlue !important;
          margin-top: -35px;
          padding-top: 33px;
          padding-bottom: 36px !important;
          margin-bottom: -20px;
          border-radius: 1rem;
          background: $generalColorWhite;
        }
        ul {
          margin-top: 1.3rem;
          li {
            display: block;
            width: 100%;
            padding: $spacingXXXS 0;
            text-align: center;
            border-bottom: 1px solid $placeholderGray;
          }
        }
        &:last-child {
          border-radius: 0 1rem 1rem 0;
        }
      }
      span {
        display: block;
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: $fontSize16;
        & + span {
          font-weight: normal;
          padding: $spacingXXXS 0;
          font-size: $fontSize18;
        }
        & + span + span {
          color: $primaryBlue;
          margin-top: $spacingXXXS;
          padding-top: $spacingXXS;
          border-bottom: 1px solid $placeholderGray;
          position: relative;
          .price {
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            top: -3px;
            background-color: $generalColorWhite;
            max-width: 88px;
          }
        }
      }
    }
  }
}
@media (max-width: 1280px) {
  .plan-type {
    div {
      &:nth-child(1) {
        padding-left: 1.5rem !important;
      }
    }
  }
}
@media (max-width: 1199px) {
  .payment-plans {
    border: none !important;
    background-color: transparent !important;
    .plan-type {
      justify-content: center !important;
      div {
        -webkit-flex: 0 1 22% !important;
        flex: 0 1 22% !important;
        transition: all 0.5s;
        margin: 0 3px 50px 3px;
        border: 1px solid #ddd !important;
        border-radius: 1rem !important;
        &:nth-child(1) {
          display: none !important;
        }
        &:nth-child(2) {
          border-right: 1px solid #ddd !important;
        }
        &.active {
          border: 3px solid $primaryBlue !important;
          margin-bottom: 50px !important;
          border-radius: 1rem;
          background: $generalColorWhite;
          padding-top: 2rem !important;
          text-align: center;
          margin-top: -20px !important;
        }
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          ul {
            li {
              &::before {
                display: block;
                font-weight: 600;
                color: $fontColorBlack;
                opacity: 0.7;
              }
            }
            li:nth-child(1) {
              &:before {
                content: "Dashboard";
              }
            }
            li:nth-child(2) {
              &:before {
                content: "Client Portal";
              }
            }
            li:nth-child(3) {
              &:before {
                content: "Messaging";
              }
            }
            li:nth-child(4) {
              &:before {
                content: "Notifications";
              }
            }
            li:nth-child(5) {
              &:before {
                content: "Language [En & Fr]";
              }
            }
            li:nth-child(6) {
              &:before {
                content: "From Builder";
              }
            }
            li:nth-child(7) {
              &:before {
                content: "Forms";
              }
            }
            li:nth-child(8) {
              &:before {
                content: "From Analysis";
              }
            }
            li:nth-child(9) {
              &:before {
                content: "Submissions";
              }
            }
            li:nth-child(10) {
              &:before {
                content: "Services";
              }
            }
            li:nth-child(11) {
              &:before {
                content: "Sectors";
              }
            }
            li:nth-child(12) {
              &:before {
                content: "Programs";
              }
            }
            li:nth-child(13) {
              &:before {
                content: "Contact Management";
              }
            }
            li:nth-child(14) {
              &:before {
                content: "Advance Analytics";
              }
            }
            li:nth-child(15) {
              &:before {
                content: "Permissions";
              }
            }
            li:nth-child(16) {
              &:before {
                content: "Calendars";
              }
            }
            li:nth-child(17) {
              &:before {
                content: "Mobile App";
              }
            }
            li:nth-child(18) {
              &:before {
                content: "APIs Integrations";
              }
            }
            li:nth-child(19) {
              &:before {
                content: "Backend Generic";
              }
            }
            li:nth-child(20) {
              &:before {
                content: "Workflows";
              }
            }
            li:nth-child(21) {
              &:before {
                content: "Custom Branding";
              }
            }
            li:nth-child(22) {
              &:before {
                content: "Dashboard";
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .payment-plans {
    .plan-type {
      justify-content: center !important;
      div {
        -webkit-flex: 0 1 45% !important;
        flex: 0 1 45% !important;
      }
    }
  }
}

@media (max-width: 420px) {
  .payment-plans {
    .plan-type {
      justify-content: center !important;
      div {
        -webkit-flex: 0 1 90% !important;
        flex: 0 1 90% !important;
      }
    }
  }
}
