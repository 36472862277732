@import "../../styles/global.main.scss";

@mixin event-item() {
  border-radius: 4px;
  padding: 2px 10px;
  color: #000;
  font-size: 11px;
}

.rbc-event-label {
  display: none;
}

.rbc-calendar {
  background-color: $backgroundColorMain;
  border-radius: 10px;
  position: relative;
  padding-bottom: 60px;

  .rbc-row-segment {
    padding: 2px 4px 2px 3px;
    margin: 0;
    height: 28px;
    font-weight: 600;
  }
  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 0 !important;
    background-color: transparent !important;
    border-radius: 0 !important;
    color: unset !important;
    cursor: pointer;
    width: 100%;
    text-align: left;
  }

  .rbc-event.waiting-wrapper {
    background: transparent !important;
    background-color: $calendarDeepSkyBlueBg !important;
    border: 1px solid $primaryBlue;
    border-radius: 4px !important;
    .rbc-event-content {
      @include event-item();
    }
  }
  .rbc-event.cancelled-wrapper {
    background-color: $calendarFadedRedBg !important;
    border: 1px solid $semanticColorError;
    border-radius: 4px !important;
    .rbc-event-content {
      @include event-item();
    }
  }

  .rbc-event.is-missed-wrapper {
    background-color: $calendarSquashBg !important;
    border: 1px solid $fontColorWarning;
    border-radius: 4px !important;
    .rbc-event-content {
      @include event-item();
    }
  }

  .rbc-event.completed-wrapper {
    background-color: $semanticColorSuccess !important;
    border: 1px solid $scoreExcellentColor;
    border-radius: 4px !important;
    .rbc-event-content {
      @include event-item();
      color: $generalColorWhite;
    }
  }

  .holiday-wrapper {
    padding: 0 0.5rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 120px;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: -19px;
      left: 0;
      background: $backgroundColorGray;
      display: inline-flex;
      min-height: 163px;
      z-index: -1;
    }
    .holiday {
      font-weight: 600;
      color: $borderColorLightGray;
    }
  }
  .rbc-event.completed-wrapper {
    background-color: $calendarSuccessBg !important;
    border: 1px solid $calendarSuccessBorder;
    border-radius: 4px !important;
    .rbc-event-content {
      @include event-item();
    }
  }
}

.rbc-toolbar {
  display: block !important;
  justify-content: unset !important;
  align-items: unset !important;
  position: relative;
  padding: 25px 40px 20px 40px;
  border-bottom: 1px solid $paleGray;
}
.rbc-toolbar .rbc-toolbar-label {
  flex-grow: unset;
  position: absolute;
  left: 60px;
  color: $primaryBlue;
  font-weight: bold;
  font-size: $fontSize16;
  width: 230px;
}
.rbc-toolbar .rbc-btn-group:first-child {
  left: 0;
  position: relative;
  text-transform: uppercase;
  width: 180px;
  height: 25px;
}

.rbc-toolbar .rbc-btn-group:last-child {
  left: 30%;
  position: relative;
  text-transform: uppercase;
}

.rbc-toolbar .rbc-btn-group:last-child button:nth-child(1) {
  background: transparent;
  box-shadow: none;
  visibility: hidden;
  position: relative;
  width: 130px;
  &:after {
    visibility: visible;
    content: "Monthly";
    position: absolute;
    left: 0;
    width: 120px;
  }
}
.rbc-toolbar .rbc-btn-group:last-child button:nth-child(2) {
  background: transparent;
  box-shadow: none;
  visibility: hidden;
  position: relative;
  width: 120px;
  &:after {
    visibility: visible;
    content: "Weekly";
    position: absolute;
    left: 0;
    width: 120px;
  }
}
.rbc-toolbar .rbc-btn-group:last-child button:nth-child(3) {
  background: transparent;
  box-shadow: none;
  visibility: hidden;
  position: relative;
  width: 120px;
  &:after {
    visibility: visible;
    content: "Daily";
    position: absolute;
    left: 0;
    width: 120px;
  }
}
.rbc-toolbar .rbc-btn-group:last-child button:nth-child(4) {
  display: none;
}

.rbc-toolbar .rbc-btn-group:first-child button:nth-child(1) {
  left: 300px;
  position: absolute;
  text-transform: uppercase;
  color: $primaryBlue;
  font-weight: bold;
  font-size: $fontSize16;
  top: 0;
  padding: 0;
  margin: 0;
}
.rbc-toolbar .rbc-btn-group:first-child button:nth-child(2) {
  visibility: hidden;

  &:after {
    content: "";
    background-image: url(../../assets/images/icon-arrow-left.svg);
    background-repeat: no-repeat;
    width: 10px;
    height: 18px;
    visibility: visible;
    display: block;
    position: absolute;
    padding: 5px;
    top: 2px;
    left: 0;
    transform: rotate(180deg);
  }
}

.rbc-toolbar .rbc-btn-group:first-child button:nth-child(3) {
  visibility: hidden;
  margin-left: 190px;
  #sidebar {
    width: auto;
  }
  &:before {
    content: "";
    background-image: url(../../assets/images/icon-arrow-left.svg);
    background-repeat: no-repeat;
    width: 10px;
    visibility: visible;
    display: block;
    position: absolute;
    padding: 5px;
    top: 2px;
    left: 260px;
    width: 10px;
    height: 18px;
  }
}
.rbc-active {
  &:after {
    padding-bottom: 24px;
    border-bottom: 2px solid $primaryBlue;
    font-weight: bold;
  }
}
.rbc-month-view {
  border: 0 !important;
}
.rbc-row.rbc-month-header {
  .rbc-header {
    padding: 29px 4px 5px 3px;
    text-align: right;
    color: $primaryBlue;
  }
}
.rbc-header + .rbc-header {
  border-left: 0 !important;
}
.rbc-month-row {
  min-height: calc(60vh / 5);
}
