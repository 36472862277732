@import "../../../../../../../styles/global.main.scss";
.feedback-company-details {
  min-width: 230px;
  figure {
    width: 81px;
    height: 81px;
    border: 1px solid $placeholderGray;
    border-radius: 16px;
    overflow: hidden;
    display: block;
    img {
      width: 100%;
      height: 100%;
    }
  }
  div {
    margin-bottom: $spacingXS;
    span {
      display: block;
    }
  }
}
