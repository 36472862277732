@import "../../../../../../styles/global.main.scss";

.role-wrapper {
  @mixin row-two-column() {
    display: grid;
    grid-template-columns: 320px auto;
    background: $generalColorWhite;
    border-bottom: 1px solid $placeholderGray;
    div,
    span {
      font-size: $fontSize16;
      font-weight: bold;
      &:first-child {
        padding-left: 30px;
      }
    }
  }
  .role-header {
    @include row-two-column();
  }
  .role-search {
    padding: $spacingXXS $spacingS $spacingXS $spacingS;
  }
  .role-list {
    ul {
      li {
        .head {
          @include row-two-column();
          padding: 15px 0;
          cursor: pointer;
          span {
            &:last-child {
              font-size: $fontBaseSize;
              font-weight: 600;
              margin-top: 3px;
              i {
                float: right;
                margin-right: $spacingXS;
                margin-top: $spacingXXXS;
                width: 14px;
                height: 7px;
                transform: rotate(180deg);
                background: url(../../../../../../assets/icons/arrow-down-role.svg)
                  no-repeat center center;
              }
            }
            &.inactive {
              color: $fontPrimaryColor;
            }
          }
        }
        .access-permissions {
          display: none;
          padding: $spacingXS $spacingS;
          &.active {
            display: block;
          }
          .feature-list {
            display: grid;
            grid-template-columns: 390px auto;
          }
          .permissions {
            display: grid;
            grid-template-columns: 0 auto;
            input[type="checkbox"] {
              opacity: 0;
              & + label {
                position: relative;
                color: $fontColorBlack;
                margin-bottom: $spacingXS;
                &:after {
                  content: "";
                  width: 18px;
                  height: 18px;
                  border-radius: 4px;
                  border: 1px solid $placeholderGray;
                  position: absolute;
                  right: $spacingXL;
                  top: 0;
                }
              }
              &:checked {
                & + label {
                  &:after {
                    content: "";
                    background-image: url(../../../../../../assets/icons/tickBlue.png);
                    background-size: 80%;
                    background-repeat: no-repeat;
                    background-position: 1px 3px;
                    filter: grayscale(100%);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.define-new-roles {
  position: absolute;
  bottom: $spacingXXXS;
  z-index: 99;
  left: 0;
  width: 100%;
  padding: $spacingXS $spacingXXS;
}
