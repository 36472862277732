/**
 * @tokens Colors
 * @presenter Color
 */

// brand colors
$brandColorPrimary: #1b84e7;
$brandColorPrimaryDisabled: #ffd466;
$brandColorSecondary: #3063c8;
$brandColorSecondaryDisabled: #7296df;
$brandColorTertiary: #dddddd;
$brandColorTertiaryDisabled: #eaeaea;

// Semantic colors
$semanticColorError: #dc3545;
$semanticColorWarning: #f49917;
$semanticColorInformation: #3063c8;
$semanticColorSuccess: #23bf08;
$semanticColorSuccessDisabled: #b8e1a9;
$semanticColorDisabled: rgba(0, 0, 0, 0.4);

// Semantic color hover states
$semanticColorWarningHover: #e2ab1c;
$semanticColorInformationHover: #294e99;
$semanticColorDefaultHover: #b0b0b0;

// Font colors
$fontColorBlack: #495057;
$fontColorClientLanguages: #79818b;
$fontColorDarkGray: #595959; // @todo Rename all fontColorDarkGray to assistive to follow design system
$fontColorAssistive: #595959;
$placeholderGray: #dee2e6;
$fontColorLightGray: #dee2e6;
$fontColorInformation: $semanticColorInformation;
$fontColorError: $semanticColorError;
$fontColorWarning: #ca9306;
$primaryBlue: #1b84e7;
$fontPrimaryColor: #b7bdc3;
// General colours
$backgroundColorOffWhite: #f8f8f8;
$backgroundColorSection: #f8f7f4;
$backgroundColorSectionHover: #efeeea;
$backgroundColorMain: #ffffff;
$backgroundColorGray: #fafafa;
$backgroundColorLightBlue: #f1f7ff;
$generalColorWhite: #ffffff;
$paleGray: #f0f2f7;
$backgroundColorLightSilver: #dee2e6;
$calendarSuccessBg: #b7e0af;
$calendarSuccessBorder: #86c78c;
$calendarSquashBg: rgba(244, 153, 23, 0.2);
$calendarFadedRedBg: rgba(220, 53, 69, 0.2);
$calendarDeepSkyBlueBg: rgba(27, 132, 231, 0.2);
$calendarLightSilverBg: rgba(183, 189, 195, 0.2);
$paymentButtonActiveBg: #eff6fe;
$groupLabelBg: #9daabf;
$chatBackground: #fcfcfc;
$chatIncoming: #eff3fd;
$chatIncomingBorder: rgba(27, 132, 231, 0.3);
$ratingPercentYellow: #fcc14a;
// Input colours
$selectedColorLightGray: #f1f1f1;

// Buttons

// Separator colours
$borderSeparatorColorOffWhite: #e1e1e1;
$borderColorLightGray: #b7bdc3;

// Scoring colors
$scoreExcellentColor: #4ea256;
$scoreExcellentBackgroundColor: #f1fff3;
$scoreGoodColor: #bd8e15;
$scoreGoodBackgroundColor: #fff7e2;
$scoreAverageColor: #a24e4e;
$scoreAverageBackgroundColor: #fff1f1;
