@import "../../../../../../styles/global.main.scss";

@mixin payment-button() {
  padding-left: $spacingXL;
  margin-bottom: $spacingXS;
  border-radius: 16px;
  font-size: $fontSize16;
  color: $fontPrimaryColor;
  font-weight: 600;
  width: 210px;
  height: 80px;
  background-position: 15px 20px !important;
  background-repeat: no-repeat !important;
  text-align: left;
  border: 1px solid $placeholderGray !important;
}
.add-payment-window {
  position: relative;
  .add-payment-method {
    .wrapper {
      display: grid;
      grid-template-columns: 250px auto;
      ul {
        padding-top: $spacingXS;
        li {
          button {
            @include payment-button();
            &.chargebee {
              background-image: url(../../../../../../assets/icons/iconChargeBee.png) !important;
              box-shadow: 0 4px 8px 0 rgba(167, 190, 255, 0.3);
              background: $paymentButtonActiveBg;
              border: 3px solid $primaryBlue !important;
              color: $primaryBlue;
            }
            &.paypal {
              background-image: url(../../../../../../assets/icons/iconPaypal.png) !important;
              filter: grayscale(100%);
            }
            &.bank {
              background-image: url(../../../../../../assets/icons/iconBank.png) !important;
              filter: grayscale(100%);
            }
          }
        }
      }
      .form-element {
        margin-top: $spacingXS;
        label {
          strong {
            color: $primaryBlue;
            font-weight: bold;
          }
        }
        input[type="checkbox"] {
          display: none;
          position: absolute;
          & + label {
            padding-left: $spacingS;
            position: relative;
            &:before {
              content: "";
              width: 18px;
              height: 18px;
              position: absolute;
              left: 0;
              top: 0;
              border-radius: 4px;
              border: 1px solid $placeholderGray;
            }
          }
          &:checked {
            & + label {
              &:before {
                background-image: url(../../../../../../assets/icons/tickBlue.png);
                background-repeat: no-repeat;
                background-position: 2px 3px;
                background-size: 80%;
              }
            }
          }
        }
      }
    }
    .bottom {
      margin-top: $spacingXS;
      a {
        font-weight: bold;
      }
    }
  }
  .external-link {
    position: absolute;
    bottom: 1.2rem;
    left: 0;
  }
}
