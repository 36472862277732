@import "../../../../../../styles/global.main.scss";

.create-service-main {
  .img-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    .info-box {
      max-width: 80%;
      border: 1px solid $placeholderGray;
      border-radius: 18px;
      padding: $spacingXS;
      box-shadow: 0 4px 6px 0 rgba(167, 190, 255, 0.2);
      background: $generalColorWhite;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        margin-left: $spacingXXS;
        font-weight: 600;
        font-size: 16px;
        color: $fontColorDarkGray;
      }
    }
  }
  .create-service-form {
    background: $generalColorWhite;
    padding: 2rem 4rem;
    display: block;
    .form {
      max-width: 500px;
      .form-group {
        margin-top: 2rem !important;
      }
    }
  }
}
