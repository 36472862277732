.add-contact-page {
  .page-left {
    display: inline-block;
    width: 45%;
  }
  .page-right {
    display: inline-block;
    width: 50%;
    margin-left: 5%;
  }
  .button-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }
  @media (max-width: 1366px) {
    .page-left {
      display: inline-block;
      width: 100%;
    }
    .page-right {
      display: inline-block;
      width: 100%;
      margin-left: 0%;
    }
  }
  @media (max-width: 1280px) {
    .button-wrapper {
      bottom: -50px;
      right: 2rem;
    }
  }
}
