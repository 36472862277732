@import "../../../styles/global.main.scss";

.dashboard-header {
  column-count: 3;
  display: grid;
  grid-template-columns: 350px auto 520px;
  padding: $spacingXXXS $spacingXXS;
  position: fixed;
  z-index: 99;
  top: 0;
  background: $generalColorWhite;
  box-shadow: 0 4px 6px 0 rgba(167, 190, 255, 0.35);
  .grid-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    &:last-child {
      justify-content: flex-end;
    }
    .businessName {
      font-size: $fontSize30;
      font-weight: 600;
      color: $primaryBlue;
      margin-right: $spacingXXS;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  ul {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    li {
      display: inline-block;
      margin: 0 $spacingS;
      &.bell {
        position: relative;
        cursor: pointer;
        img {
          position: relative;
          z-index: 0;
        }
        span {
          position: absolute;
          width: 10px;
          height: 10px;
          background: $semanticColorError;
          bottom: 0px;
          left: 12px;
          border-radius: 50%;
          right: 0;
          margin: 0 auto;
          z-index: 2;
        }
      }
      &.avatar {
        min-width: 50px;
        min-height: 50px;
        border-radius: 100%;
        border: 1px solid $primaryBlue;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          border-radius: 100%;
        }
        .status {
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background: $semanticColorSuccess;
          bottom: 0;
          right: 0;
        }
      }

      &.nav {
        position: relative;
        padding: 0;
        margin: 0;
        span {
          padding: 15px 0;
          cursor: pointer;
          font-size: $fontBaseSize;
          img {
            margin-left: 20px;
          }
        }
        .sub-menu {
          display: none;
          position: absolute;
          top: 35px;
          right: 0;
          width: 160px;
          background-color: $generalColorWhite;
          text-align: left;
          padding: $spacingXXS;
          border: 1px solid #ddd;
          z-index: 10;
          a {
            display: block;
            width: 100%;
            padding: $spacingXXXS 0;
            color: $fontColorBlack !important;
            font-size: $fontBaseSize;
            border-bottom: 1px solid $placeholderGray;
            &:hover {
              text-decoration: none;
              opacity: 0.8;
            }
          }
          &.show {
            display: block;
          }
        }
        button {
          display: block;
          color: $fontColorBlack !important;
          font-size: $fontSize16;
          text-align: left;
          &:hover {
            text-decoration: none;
            opacity: 0.8;
          }
        }
        &:hover {
          .sub-menu {
            display: block;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1280px) {
    grid-template-columns: 170px auto 500px;
  }
  @media screen and (max-width: 991px) {
    grid-template-columns: auto auto auto;
    .avatar {
      margin: 0 $spacingXXS;
    }
    .grid-item:nth-child(3) {
      span.userName {
        display: none;
      }
      .sub-menu {
        left: -100px;
      }
    }
  }
  @media (max-width: 767px) {
    padding: $spacingXXXS;
    transition: all 0.5s;
    ul {
      li {
        margin: 0 $spacingXXXS;
        &.avatar {
          min-width: 35px;
          min-height: 35px;

          img {
            border-radius: 100%;
          }
          .status {
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background: $semanticColorSuccess;
            bottom: 0;
            right: 0;
          }
        }
      }
    }
  }
}
