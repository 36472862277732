@import "../../../../../../styles/global.main.scss";
@import "../../../../../../styles/customComponents/genericTabs.scss";
.applied-service {
  position: relative;
  .back-section {
    width: 135px;
    position: relative;
    button.btn-back {
      position: absolute;
      top: -25px;
      left: 2rem;
      z-index: 5;
      border-bottom: 1px solid $placeholderGray !important;
      &:hover {
        border-bottom: 1px solid $primaryBlue !important;
      }
    }
  }
  .right-side {
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    .add-review-title {
      display: flex;
      height: 80px;
      margin-top: -15px;
      min-width: 560px;
      align-items: center;
      font-size: $fontSize16;
      font-weight: 700;
      border-left: 1px solid $placeholderGray;
      padding-left: $spacingXS;
    }
  }
  .left-block {
    width: 32%;
    display: inline-block;
    border-right: 1px solid $placeholderGray;
    h4 {
      font-weight: bold;
    }
  }
  .right-block {
    width: 68%;
    height: 100%;
    .consultant-area {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .order-number {
      font-size: $fontSize20;
      color: $fontPrimaryColor;
    }
  }
  @media screen and (max-width: 1600px) {
    .right-side {
      .add-review-title {
        min-width: 420px;
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .right-side {
      .add-review-title {
        min-width: 380px;
      }
    }
  }
  @media screen and (max-width: 1366px) {
    .right-side {
      .add-review-title {
        display: none;
      }
    }
    .left-block {
      width: 40%;
    }
    .right-block {
      width: 60%;
    }
  }
  @media screen and (max-width: 1024px) {
    .left-block {
      width: 100%;
      border-right: 0;
    }
    .right-block {
      width: 100%;
    }
  }
}
