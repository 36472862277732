@import "../../../../../styles/global.main.scss";

.find-client-service {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: $spacingXS;
  .service-box {
    box-shadow: 0 4px 6px 0 rgba(167, 190, 255, 0.2) inset;
    width: 365px;
    height: 202px;
    border: 1px solid $placeholderGray;
    margin: $spacingXS $spacingXS 0 0;
    border-radius: 8px;
    .top,
    .bottom {
      padding: $spacingXXS;
    }
    .top {
      height: 120px;
      div.description {
        padding: $spacingXXS 0;
        margin-bottom: $spacingXXS;
        line-height: 1.5em;
        height: 60px;
        overflow: hidden;
      }
      .features {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          &:first-child {
            min-width: 200px;
          }
          img {
            margin-right: 5px;
          }
        }
      }
    }
    .bottom {
      padding-top: 0;
      display: flex;
      height: 80px;

      align-items: center;

      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        min-width: 48px;
        overflow: hidden;
        margin-right: $spacingXS;
        border-radius: 100%;
        border: 1px solid $placeholderGray;
        img {
          width: 100%;
        }
      }
      div {
        display: block;
        width: 100%;
        h4 {
          margin-bottom: $spacingXXXS;
        }
        div.rating-space {
          display: flex;
          justify-content: space-between;
          align-items: center;
          div {
            max-width: 90px;
          }
          button {
            background: transparent;
            opacity: 0;
            font-weight: 700;
            color: $primaryBlue;
          }
        }
      }
    }
    hr.divider {
      border: 0;
      border-bottom: 1px solid #ddd !important;
      margin-bottom: 0;
    }
    h4 {
      font-size: $fontSize16;
      font-weight: 600;
      padding: 0;
      margin: 0;
    }
    &:hover {
      border-color: $primaryBlue;
      .bottom {
        div {
          div.rating-space {
            button {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
