@import "../global.main.scss";
.invitations {
  position: relative;
  transition: all 0.5s;
  display: flex;
  .menu-mobile {
    display: none;
    position: fixed;
    right: 1rem;
    z-index: 8;
    top: 9%;
    width: 32px;
    height: 32px;
    border: 0;
    background: url(../../assets/images/menu.png) no-repeat;
    &:focus,
    &:active {
      outline: none;
      border: 0;
    }
  }
  .menu {
    position: fixed;
    max-width: 250px;
    width: 250px;
    height: 100vh;
    background-color: $backgroundColorGray;
  }
  .content {
    width: 100%;
    margin-left: 260px;
    .title {
      position: relative;
      padding-left: $spacingS;
      &:before {
        content: "";
        height: 33px;
        width: 7px;
        background-color: $primaryBlue;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .left-side {
    background-color: $backgroundColorGray;
    height: 100vh;
    text-align: center;
    min-width: 250px;
    max-width: 250px;
    padding-top: $spacingS;
    .inner-left {
      height: 90%;
      width: 100%;
      display: flex;
      -ms-flex-pack: flex-start !important;
      justify-content: flex-start !important;
      align-items: center;
      color: $primaryBlue;
      font-weight: 600;
      font-size: $fontSize16;
      padding-left: $spacingS;
    }
  }
  .right-side {
    padding: $spacingXXXL $spacing4XL;
    width: 100%;
    header {
      font-size: $fontSize40;
      font-weight: bold;
      color: $primaryBlue;
      line-height: 1.4;
      margin-bottom: 104px;
      small {
        font-size: $fontSize20;
        color: $fontColorBlack;
        display: block;
      }
    }
  }
  @media (max-width: 1180px) {
    display: block !important;
    .right-side {
      padding: $spacingM $spacingXS;
      transition: all 0.5s;
      margin-left: 0;
    }
    .left-side {
      top: 0;
      height: 60px;
      text-align: left;
      padding: $spacingXS;
      position: relative;
      width: 100%;
      max-width: 100%;
      display: flex;
      .inner-left {
        height: unset;
        justify-content: flex-end !important;
      }
    }
  }
  @media (max-width: 991px) {
    .menu-mobile {
      display: block;
    }
    .menu {
      left: -250px;
      transition: 0.5s;
      top: 5.8rem;
    }
    .show {
      left: 0;
      transition: 0.5s;
      z-index: 99999;
    }
    .content {
      padding: $spacingS;
      margin: 0;
    }
  }
}
