.grid-two-column {
  display: grid;
  grid-template-columns: auto 80px;
  padding: 0;
  margin-bottom: 3rem;
  h4,
  p {
    padding: 0;
    margin: 0;
  }
}
