@import "../../../../styles/global.main.scss";

.business-stats {
  .box {
    min-width: 100%;
    height: 75px;
    padding: 1rem 1rem;
    box-shadow: 0 3px 6px 0 rgba(167, 190, 255, 0.3);
    border: solid 1px $fontColorLightGray;
    background-color: $generalColorWhite;
    display: grid;
    border-radius: 0.5rem;
    grid-template-columns: 22% 60% 18%;
    align-items: center;

    > div {
      h3 {
        font-size: $fontSize14;
        font-weight: 600;
      }
      span {
        font-weight: bold;
        color: $primaryBlue;
        display: block;
        font-size: $fontSize30;
        text-align: right;
      }
      small {
        font-size: 1.1rem;
      }
    }
    .icon {
      grid-column: 1 / span 1;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      border: 1px solid $primaryBlue;
      border-radius: 100%;
      width: 40px;
      height: 40px;
    }
  }
  @media (max-width: 1280px) {
    .box {
      margin: 0 $spacingXXXS $spacingXXXS 0;
      width: 48%;
    }
  }

  @media (max-width: 767px) {
    .box {
      margin: 0 $spacingXXXS $spacingXXXS 0;
      width: 49%;
    }
  }

  @media (max-width: 600px) {
    .col-xl-3 {
      margin: $spacingXXXS 0;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .box {
      margin: 0 $spacingXXXS $spacingXXXS 0;
      width: 100%;
    }
  }
}
