@import "./mixins/typography";

.label {
}

.noBreakText {
  white-space: nowrap;
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.link {
  color: $primaryBlue;
  text-decoration: none;

  &:focus {
    color: $primaryBlue;
  }

  &:hover {
    color: $fontColorBlack;
  }

  &--active,
  &.disabled,
  [disabled] {
    color: $primaryBlue;
    pointer-events: none;
  }
}

// type styles from mixins
.title {
  @include title1;
}

.headline {
  @include headline;
}

.bodyCopy {
  @include bodyCopy;
}
