@import "../../../../../../styles/global.main.scss";

.chat-wrapper {
  @mixin avatar() {
    img {
      width: 37px;
      height: 37px;
    }
  }
  @mixin sender-title() {
    span {
      display: block;
      font-size: $fontSizeSmall;
      margin-bottom: 0.3rem;
      span {
        display: inline-block;
        margin-left: $spacingXXS;
      }
    }
  }
  @mixin message-box($bg, $borderColor, $alignment, $float) {
    p {
      border-radius: 8px;
      border: 1px solid $borderColor;
      padding: $spacingXXS;
      background-color: $bg;
      text-align: $alignment;
      max-width: 80%;
      float: $float;
      &.typing {
        border: 0;
        background: transparent;
        padding: 0;
        color: $placeholderGray;
        font-size: $fontSize20;
        -webkit-animation: fadein 0.5s ease-in alternate infinite;
        -moz-animation: fadein 0.5s ease-in alternate infinite;
        animation: fadein 0.5s ease-in alternate infinite;
        @-webkit-keyframes fadein {
          from {
            opacity: 0.5;
          }
          to {
            opacity: 1;
          }
        }
        @-moz-keyframes fadein {
          from {
            opacity: 0.5;
          }
          to {
            opacity: 1;
          }
        }
        @keyframes fadein {
          from {
            opacity: 0.5;
          }
          to {
            opacity: 1;
          }
        }
      }
    }
  }
  @mixin shared-file($aligment) {
    .share-box {
      border: 1px solid $placeholderGray;
      width: 200px;
      height: 160px;
      border-radius: 8px;
      overflow: hidden;
      float: $aligment;
      .file {
        padding: 1px;
      }
      button {
        width: 50%;
        padding: $spacingXXXS 0;
        color: $primaryBlue;
        background: $backgroundColorGray;
      }
    }
  }
  background: $chatBackground;

  .chat-box {
    padding: $spacingXS $spacingXS 0 $spacingXS;
    .date {
      max-width: 313px;
      margin: 0 auto $spacingM auto;
      border-radius: 8px;
      border: 1px solid $placeholderGray;
      text-align: center;
      font-size: $fontSizeSmall;
      padding: $spacingXXXS 0;
    }
    .messages {
      li {
        display: block;
        width: 100%;
        .incoming {
          display: grid;
          grid-template-columns: 50px auto;
          justify-content: flex-start;
          margin-bottom: $spacingXS;
          .avatar {
            @include avatar();
          }
          .message {
            @include sender-title();
            @include message-box(
              $chatIncoming,
              $chatIncomingBorder,
              left,
              left
            );
            .attachment {
              display: block;
              width: 100%;
              clear: both;
              @include shared-file(left);
            }
          }
        }
        .sent {
          justify-content: flex-end;
          text-align: right;
          display: grid;
          grid-template-columns: auto 50px;
          margin-bottom: $spacingXS;
          .avatar {
            @include avatar();
          }
          .message {
            @include sender-title();
            @include message-box(
              $backgroundColorGray,
              $placeholderGray,
              left,
              right
            );
            .attachment {
              display: block;
              width: 100%;
              clear: both;
              @include shared-file(right);
            }
          }
        }
      }
    }
  }
  .chat-footer {
    height: 100px;
    background: $generalColorWhite;
    display: grid;
    grid-template-columns: 60px auto;
    align-items: center;
    padding: $spacingXS;
    .btn-add-circle-blue {
      top: 0 !important;
    }
    input[type="text"] {
      padding: $customInputText;
      width: 100%;
      display: inline-block;
      height: 44px;
      border: solid 1px $placeholderGray;
      border-radius: 26px 0 0 26px;
    }
    button.send-message {
      background: url("../../../../../../assets/images/icon-send-message.png")
        center center no-repeat;
      width: 80px;
      height: 44px;
      display: inline-block;
      border-radius: 0 26px 26px 0;
      border: solid 1px $placeholderGray !important;
      border-left: 0 !important;
    }
  }
}
