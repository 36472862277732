// @import './debug';
@import './variables/fonts';
@import './variables/colors';
@import './variables/buttons';
/* @import './flexboxgrid'; */
@import './typography';
@import './layout';
@import './customComponents/md-editor.scss';

html {
  box-sizing: border-box;
  /* stylelint-disable-next-line unit-whitelist */
  font-size: 10px;

  height: 100%;
}
.row > * {
  flex-shrink: unset !important;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: inherit;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-size: $fontBaseSize !important;
  font-family: $fontPrimary;
  line-height: $fontBaseLineHeight;
  color: $fontColorBlack !important;
}
body.fontLoaded {
  font-family: $fontPrimary;
}
a {
  color: $primaryBlue;
  text-decoration: none !important;
}
ul {
  margin: 0;
  padding: 0;
  text-align: left;
  list-style: none;
}
dl,
ol,
ul {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
}
.height-auto {
  height: 100vh !important;
}
.bg-paleGray {
  background-color: $paleGray;
  min-height: 100vh;
  height: 100%;
}
.rounded-xl {
  border-radius: 0.8rem !important;
}

.rounded-xxl {
  border-radius: 2rem !important;
}

.text-primary-blue {
  color: $primaryBlue;
}

.dropdown-toggle::after {
  vertical-align: 0.055em !important;
}
// HTML Elements
input,
select,
option,
tel,
textarea {
  outline: none;
  &::placeholder {
    color: $fontPrimaryColor;
  }
  &::-moz-placeholder {
    color: $fontColorDarkGray;
  }
}
.form-group {
  margin-top: $spacingS;
}
button {
  border: 0px !important;
}
button:focus,
button:active {
  outline: none !important;
  border: 0 !important;
  box-shadow: none !important;
}

.custom-phone {
  input[type='tel'] {
    padding: 1rem 0rem 1rem 6rem;
    width: 100%;
    height: 44px !important;
    height: 4rem;
    border-radius: 26px;
    box-shadow: 0 5px 15px 0 rgba(167, 190, 255, 0.2) inset;
    border: solid 1px $borderColorLightGray;
  }
}

.custom-input {
  padding: $customInputText;
  width: 100%;
  height: 44px;
  border-radius: 26px;
  box-shadow: 0 5px 15px 0 rgba(167, 190, 255, 0.1),
    0 5px 15px 0 rgba(167, 190, 255, 0.2) inset;
  border: solid 1px $placeholderGray;
  &.search {
    background-image: url(../assets/icons/icon-search-blue-23x23.png);
    background-repeat: no-repeat;
    background-position: $spacingXS center;
    padding-left: $spacingL;
    color: $primaryBlue;
    &::placeholder {
      color: $primaryBlue;
    }
    &::-moz-placeholder {
      color: $primaryBlue;
    }
  }
  &.phone {
    background-image: url(../assets/images/flag_CA.png);
    background-repeat: no-repeat;
    background-position: 5% center;
    padding-left: $spacingXL;
  }
  &.icon-success {
    background-image: url(../assets/icons/icon-green-tick-small.png);
    background-repeat: no-repeat;
    background-position: 95% center;
  }
  &.icon-warning {
    background-image: url(../assets/icons/icon-warning-small.png);
    background-repeat: no-repeat;
    background-position: 95% center;
  }
  &.icon-danger {
    background-image: url(../assets/icons/icon-danger-small.png);
    background-repeat: no-repeat;
    background-position: 95% center;
  }
}
.custom-textarea {
  padding: $spacingXS !important;
  width: 100%;
  min-height: 100px;
  border-radius: 26px;
  box-shadow: 0 5px 15px 0 rgba(167, 190, 255, 0.1),
    0 5px 15px 0 rgba(167, 190, 255, 0.2) inset;
  border: solid 1px $placeholderGray;
  // color: $fontPrimaryColor !important;
}
#badge-danger {
  background-image: url(../assets/icons/icon-text-danger.png);
  background-repeat: no-repeat;
  background-position: left center;
  color: $semanticColorError;
  padding-left: $spacingXS;
  font-size: $fontBaseSize;
}
.react-tel-input {
  font-family: $fontPrimary;
  display: inline-block;

  .form-control {
    border: solid 1px $placeholderGray !important;
  }

  .flag-dropdown {
    border-radius: 26px 0 0 26px !important;
  }
  .selected-flag {
    width: 50px !important;
    padding: 0 0 0 15px;
    border-radius: 26px 0 0 26px !important;
  }
}

// removes arrows from input type numbers
// Firefox fix
input[type='number'],
input[type='tel'] {
  appearance: textfield;
  height: 44px;
}

// other browsers fix
input[type='number']::-webkit-inner-spin-button,
input[type='tel']::-webkit-inner-spin-button,
input[type='tel']::-webkit-outer-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
}

.primary-select {
  background-color: $generalColorWhite;
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      $fontColorAssistive calc(50% + 1px)
    ),
    linear-gradient(
      135deg,
      $fontColorAssistive calc(50% - 1px),
      transparent 50%
    );
  background-repeat: no-repeat;
  background-position: calc(100% - 18px) calc(1.8rem),
    calc(100% - 13px) calc(1.8rem);
  background-size: 6px 6px, 6px 6px, 1px 1.5rem;
  border-radius: 0;
  color: $fontColorBlack;
  appearance: none;
  padding: $primarySelect;
  width: 100%;
  height: 44px;
  border-radius: 26px;
  box-shadow: 0 5px 15px 0 rgba(167, 190, 255, 0.1),
    0 5px 15px 0 rgba(167, 190, 255, 0.2) inset;
  border: solid 1px $placeholderGray;
  &:invalid {
    color: #b6b9bb;
  }
  option:first-child {
    color: $fontColorBlack;
  }

  &:invalid option:not(:first-child) {
    color: $fontColorBlack;
  }
}

.blue-select {
  background-color: $generalColorWhite;
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      $fontColorAssistive calc(50% + 1px)
    ),
    linear-gradient(
      135deg,
      $fontColorAssistive calc(50% - 1px),
      transparent 50%
    );
  background-repeat: no-repeat;
  background-position: calc(100% - 36px) calc(1.8rem),
    calc(100% - 30px) calc(1.8rem);
  background-size: 6px 6px, 6px 6px, 1px 1.5rem;
  border-radius: 0;
  appearance: none;
  padding: $customInputText;
  width: 100%;
  border-radius: 26px;
  border: solid 1px $primaryBlue;
  &.select--noSelection {
    color: $primaryBlue;
  }
  option {
    &:first-child {
      color: $primaryBlue;
    }
  }
}

.modal.fade.show {
  display: inline-table;
  width: 780px;
  top: 0;
  height: auto;
  bottom: 0;
  left: 0;
  margin: auto;
  right: 0;
  overflow: hidden;
}
.modal-header {
  justify-content: flex-start !important;
  align-items: center !important;
}

@-moz-document url-prefix() {
  .modal.fade.show {
    position: absolute;
    top: 10%;
  }
}
.e-signature-canvas,
.e-signature-image,
.eSignRect {
  width: 100%;
  height: 250px;
  border-radius: 26px;
  box-shadow: 0 4px 6px 0 rgba(167, 190, 255, 0.37);
  border: solid 1px var(--azul);
}
.custom-checkbox {
  display: none;
  & + label {
    position: relative;
    overflow: hidden;
    color: $fontColorBlack;
    padding-left: $spacingXS;
    margin-right: $spacingS;
    margin-bottom: 0;
    &:before {
      content: '';
      width: 18px;
      height: 18px;
      border-radius: 4px;
      border: 1px solid $placeholderGray;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  &:checked {
    & + label {
      &:before {
        content: '';
        background-image: url(../assets/icons/tickBlue.png);
        background-size: 80%;
        background-repeat: no-repeat;
        background-position: 1px 3px;
      }
    }
  }
}
.react-tel-input {
  .readOnlyPhoneInput {
    background-color: $generalColorWhite !important;
    width: auto !important;
    border: 0 !important;
    padding-left: 0 !important;
  }
  .readOnlyPhoneInputButton {
    display: none !important;
  }
}
.no-items {
  border: 1px solid $placeholderGray;
  padding: $spacingXXS $spacingM;
  font-size: $fontSize16;
  border-radius: 8px;
  background: url(../assets/images/warning-black.png) no-repeat left 1rem center;
}
@media (max-width: 1280px) {
  .d-hide {
    display: none;
  }
}
