@import "../../../../../styles/global.main.scss";
.company-rating-review {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .average {
    margin-right: $spacingL;
    span.rate {
      font-size: $fontSize56;
      small {
        font-size: $fontSize32;
        color: $fontColorClientLanguages;
      }
    }
    .star-rating-count {
      display: flex;
      align-items: center;
      span.count {
        margin-left: $spacingXXS;
        padding-top: $spacingXXXS;
      }
    }
  }
  .percents {
    .rating-line {
      display: flex;
      font-size: $fontSizeSmall;
      font-weight: 600;
      align-items: center;
      margin-bottom: $spacingXXXS;
      .percent {
        width: 100px;
        background: $placeholderGray;
        height: 2px;
        margin: 0 $spacingXS;
        span {
          float: left;
          background: $ratingPercentYellow;
          height: 2px;
          width: 0;
        }
      }
      button {
        border: 1px solid transparent !important;
        background: transparent;
        font-size: $fontSizeSmall;
        border-radius: 4px;
        width: 38px;
        color: $fontColorClientLanguages;
        font-size: $fontSizeXSmall;
        &:hover {
          border-color: $placeholderGray !important;
        }
        &:active,
        &:focus {
          border-color: $primaryBlue !important;
          color: $primaryBlue !important;
        }
      }
    }
  }
}
