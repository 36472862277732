.dzu-dropzone {
    overflow: unset;
    background-color: #fafdff;
    border:1px dashed #1b84e7;
    margin:2rem;
    width:auto;
    min-height: 250px;
    .dzu-inputLabel {
        top:50px;
    .upload-files {
        color:#444;
        font-weight: normal;
        position: relative;
        &:before {
            content:'';
            position:absolute;
            width:63px;
            height:71px;
            top:-80px;
            left:0;
            right:0;
            margin-left:auto;
            margin-right:auto;
            background-image: url(../../assets/images/upload-icon-blue.jpg);
            background-repeat: no-repeat;
        }
    }
    }
}