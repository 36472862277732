@import "../../../../../../styles/global.main.scss";
.client-service-details {
  .communication-channels {
    div.channel {
      height: 50px !important;
      justify-content: flex-start;
      align-items: stretch;
      margin-right: 0 !important;
      display: flex;
      &:first-child {
        margin-top: 0.9rem;
      }
      span {
        &:first-child {
          min-width: 160px;
        }
      }
      .btn-transparent-border-blue {
        line-height: 0;
        &:hover {
          background-image: linear-gradient(to right, #1b84e7, #1515af 100%);
          color: $generalColorWhite !important;
        }
      }
    }
  }
  .details-bottom {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    div.detail-item {
      &:nth-child(1) {
        min-width: 360px;
      }
      &:nth-child(2) {
        min-width: 270px;
        margin-top: 2.6rem;
        label.text-primary-blue {
          color: $fontColorBlack !important;
          font-size: $fontSize16;
          margin-top: 0 !important;
        }
        h3 {
          font-weight: normal;
          font-size: $fontBaseSize;
          margin-top: 1rem;
          margin-bottom: $spacingXXXS;
        }
      }
      &:nth-child(1) {
        min-width: 360px;
      }
      .schedule {
        ul {
          li {
            span {
              max-width: 110px;
            }
          }
        }
      }
    }
  }
}
