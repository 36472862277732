.payment-card-box {
    display: grid;
    grid-template-columns:100px 180px ;
    padding:1.4rem;
    border:1px solid #dee2e6;
    max-width:300px;
    width:300px;
    margin-right:0.5rem;
    margin-bottom:0.5rem;
    border-radius:8px;
    font-weight: bold;
    div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        -ms-flex-wrap: wrap!important;
         flex-wrap: wrap!important;
        &:first-child{
            border:1px solid #dee2e6;
            border-radius:8px;
            padding:1rem;
            max-width:90px;
            justify-content: center;
        }
        small{
            padding-top:0.5rem;
            font-weight:normal;
            display: block;
            color:#1b84e7;
            font-size:11px;
        }
    }
    &.active {
        border-color:#1b84e7;
    }
}