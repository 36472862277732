@import "../../../../../../../styles/global.main.scss";
.weekly-view {
  display: block;
  width: 100%;
  overflow: hidden;
  .weekly-top {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid $placeholderGray;
    width: 100%;
    button {
      width: 53px;
      height: 53px;
      background: transparent;
    }
    button.prev {
      background: transparent
        url(../../../../../../../assets/images/arrow-left.svg) no-repeat center
        center;
      opacity: 0.5;
      border-right: 1px solid $placeholderGray !important;
    }
    button.next {
      background: transparent
        url(../../../../../../../assets/images/icon-arrow-right.svg) no-repeat
        center center;
      opacity: 0.5;
      border-left: 1px solid $placeholderGray !important;
    }
    span {
      min-width: 190px;
      text-align: center;
      font-weight: 600;
    }
  }
  .weekly-body {
    background: $backgroundColorGray;
    padding-top: $spacingXXS;
    overflow: hidden;
    ul {
      overflow: hidden;
      li {
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          height: 50px;
          width: 100%;
          background: $generalColorWhite;
          border-top: 1px solid $placeholderGray !important;
          span {
            padding-right: $spacingXS;
          }
          &:hover,
          &:active,
          &:focus {
            background: $primaryBlue;
            color: $generalColorWhite;
            opacity: 1;
          }
          &.active {
            background: $primaryBlue;
            color: $generalColorWhite;
            opacity: 1;
          }
        }
      }
    }
  }
}
