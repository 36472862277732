@import "../../../styles/global.main.scss";

.sidebar {
  height: 90vh;
  display: flex;
  padding-left: $spacingXXS;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  padding-top: 2rem;
  .mainmenu {
    .sub-menu {
      list-style: none;
      margin: 0;
      margin-left: 4.2rem;
      li {
        a {
          padding: 0.5rem 0;
          font-size: $fontSize14;
        }
      }
    }

    li {
      padding: $spacingXXXS 0;
      a {
        width: 100%;
        display: block;
        padding: $spacingXXS 0;
        font-size: $fontSize16;
        user-select: none;
        color: $fontColorBlack !important;
        svg {
          display: inline-block;
          margin-right: $spacingXS;
          margin-top: -0.3rem;
          fill: #495057;
          width: 20px;
        }
        &.active-menu {
          color: $primaryBlue !important;
          font-weight: bold;
          svg {
            fill: $primaryBlue !important;
          }
        }
        &.chat-badge {
          position: relative;

          span {
            position: absolute;
            left: 115px;
            width: 25px;
            height: 25px;
            background: $primaryBlue;
            border-radius: 50%;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -8px;
            font-size: 14px;
          }
        }
        span {
          float: right;
        }
      }
    }
  }
  ul {
    li {
      padding: $spacingXXS + 0.5 0;
      cursor: pointer;
      a {
        font-size: $fontSize16;
        color: $fontColorBlack !important;
        line-height: 1rem;
        img {
          margin-right: $spacingXS;
        }
        &.active-menu {
          color: $primaryBlue !important;
          font-weight: bold;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    left: -250px;
  }
  @media (max-width: 767px) {
    .mainmenu {
      li {
        padding: $spacingXXXS 0;
      }
    }
  }
}
