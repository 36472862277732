@import "../../../../styles/global.main.scss";
.service-list {
  background-color: $generalColorWhite;
  .is-active {
    color: $semanticColorSuccess;
  }
  .is-inactive {
    color: $semanticColorWarning;
  }
  .dots-loading {
    margin-right: 7px;
    padding: 0;
    -webkit-animation: fadein 0.5s ease-in alternate infinite;
    -moz-animation: fadein 0.5s ease-in alternate infinite;
    animation: fadein 0.5s ease-in alternate infinite;
    @-webkit-keyframes fadein {
      from {
        opacity: 0.3;
      }
      to {
        opacity: 1;
      }
    }
    @-moz-keyframes fadein {
      from {
        opacity: 0.3;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fadein {
      from {
        opacity: 0.3;
      }
      to {
        opacity: 1;
      }
    }
  }
}
