/**
 * @tokens Spacing
 * @presenter Spacing
 */

$spacing6XL: 16rem;
$spacing5XL: 14rem;
$spacing4XL: 12rem;
$spacingXXXL: 10rem;
$spacingXXL: 9rem;
$spacingXL: 8rem;
$spacingL: 6rem;
$spacingM: 4rem;
$spacingML: 5rem;
$spacingS: 3rem;
$spacingXS: 2rem;
$customInputText: 1rem 2rem 1rem 1.75rem;
$primarySelect: 1rem 2.8rem 1rem 1.75rem;
$spacingXXS: 1rem;
$spacingXXXS: 0.5rem;
$btnWhiteSpace: 1rem 2;
