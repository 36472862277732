@import "../../../styles/global.main.scss";

.innerHeader {
  padding: $spacingM 0;
  padding-bottom: 0;
  h3 {
    color: $primaryBlue;
    font-size: $fontSize20;
    font-weight: bold;
  }
  button {
    margin: 0 $spacingXXXS;
    color: $fontColorBlack;
    font-weight: 600;
    background-color: transparent;
    &:hover {
      text-decoration:none;
    }
  }
}

