@import "../global.main.scss";
.onboarding {
  position: relative;
  transition: all 0.5s;
  .payment-bg {
    position: absolute;
    right: -5%;
    top: 18%;
    z-index: -1;
  }
  .vertical-menu {
    width: 250px;
    z-index: 1001;
    background: $backgroundColorGray;
    bottom: 0;
    padding-top: $spacing4XL;
    text-align: center;
    position: fixed;
    top: 0;
    .tabs {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 76vh;
      span {
        display: block;
        padding: $spacingXXS 0;
        cursor: pointer;
        font-size: $fontSize16;
        opacity: 0.5;

        &.active {
          color: $primaryBlue !important;
          opacity: 1;
        }
      }
    }
  }
  .left {
    background-color: $backgroundColorGray;
    height: 100vh;
    padding-top: $spacingM;
    text-align: center;
    min-width: 250px;
    max-width: 250px;
    width: 20%;
    float: left;
  }
  .onboarding-section {
    padding: $spacingXXXL $spacingM;
    margin-left: 250px;
    header {
      font-size: $fontSize40;
      font-weight: bold;
      color: $primaryBlue;
      line-height: 1.4;
      margin-bottom: $spacing4XL;
      small {
        font-size: $fontSize20;
        color: $fontColorBlack;
        display: block;
      }
    }
    .tab-content {
      .tab {
        display: none;
        width: 100%;
        float: left;
        &.active {
          display: block !important;
          margin-top: 0;
        }
        .component {
          &.first {
            min-width: 450px;
            max-width: 450px;
          }
          h2 {
            strong {
              font-weight: 400 !important;
            }
          }
          .form-group {
            label {
              strong {
                color: $primaryBlue;
              }
            }
          }
          .w-50 {
            width: 48% !important;
          }
        }
      }
    }
    @media (max-width: 1660px) {
      .payment-bg {
        display: none;
      }
    }
    @media (max-width: 1499px) {
      header {
        top: 0;
        transition: all 0.5s;
      }
    }
  }
  @media (max-width: 767px) {
    .onboarding-section {
      padding: $spacingXL $spacingXS;
      transition: all 0.5s;
      margin-left: 0 !important;
      header {
        top: 6%;
        transition: all 0.5s;
        text-align: center;
        width: 100%;
        font-size: $fontSize30;
        small {
          font-size: $fontSize18;
        }
      }
      .component {
        &.first {
          min-width: 100% !important;
          max-width: 100% !important;
        }
      }
    }
    .vertical-menu {
      top: 0;
      height: 60px;
      text-align: left;
      padding: $spacingXS;
      position: relative;
      width: 100%;
      .tabs {
        height: 60px;
        position: absolute;
        top: 0rem;
        right: 1rem;
        z-index: 9;
        .select-box {
          padding: 0.5rem 1rem;
          border: 1px solid #ddd;
          font-size: 16px;
        }
        ul {
          display: none;
        }
      }
    }
  }
}
