@import "../../../../styles/global.main.scss";

.search-auto-complete {
  position: relative;
  .options,
  .no-options {
    top: 100%;
    min-width: 230px;
    position: absolute;
    padding: $spacingXXS 0;
    border: 1px solid $placeholderGray;
    border-radius: 16px;
    background: $generalColorWhite;
    z-index: 1000;
    li {
      padding: $spacingXXS $spacingXXS;
      border-bottom: 1px solid $backgroundColorSection;
      cursor: pointer;
      font-weight: 600;
    }
  }
  .no-options {
    padding: $spacingXXS $spacingXXS;
    border: 0;
  }
}