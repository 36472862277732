@import "../../../../styles/global.main.scss";

.create-new-contact {
  .contact-left {
    display: inline-block;
    width: 25%;
  }
  .contact-right {
    display: inline-block;
    width: 75%;
  }
  .contact-profile-picture {
    h2 {
      position: relative;
      button {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    figure {
      width: 210px;
      height: 204px;
      border: 1px solid $primaryBlue !important;
      border-radius: 37px;
      overflow: hidden;
      margin: 3rem auto;
      img {
        max-height: 100%;
        margin: 0 auto;
      }
    }
  }
  @media (max-width: 1366px) {
    .contact-left {
      display: inline-block;
      width: 30%;
    }
    .contact-right {
      display: inline-block;
      width: 70%;
      margin-left: 0%;
    }
  }
  @media (max-width: 1080px) {
    .contact-left {
      display: inline-block;
      width: 100%;
    }
    .contact-right {
      display: inline-block;
      width: 100%;
      margin-left: 0%;
    }
  }
}
