@import "../../../../../../styles/global.main.scss";
.applied-reviews {
  display: flex;
  justify-content: flex-start;
  .reviews-left {
    width: auto;
    padding: $spacingXS;
  }
  .reviews-right {
    min-width: 590px;
    padding: $spacingXS;
    border-left: 1px solid $placeholderGray;
  }
  @media screen and (max-width: 1600px) {
    .reviews-left {
      width: auto;
      padding: $spacingXS;
    }
    .reviews-right {
      min-width: 450px;
      padding: $spacingXS;
      border-left: 1px solid $placeholderGray;
    }
  }
  @media screen and (max-width: 1400px) {
    .reviews-left {
      width: auto;
      padding: $spacingXS;
    }
    .reviews-right {
      min-width: 410px;
      padding: $spacingXS;
      border-left: 1px solid $placeholderGray;
    }
  }
}
@media screen and (max-width: 1366px) {
  .applied-reviews {
    flex-wrap: wrap;
    .reviews-left {
      width: 100%;
      padding: $spacingXS;
    }
    .reviews-right {
      min-width: 100%;
      padding: $spacingXS;
      border-left: 1px solid $placeholderGray;
    }
  }
}
