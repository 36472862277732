@import "../../../../../../styles/global.main.scss";
.client-company-logo {
  border-radius: 16px;
  width: 100px;
  height: 100px;
  border: 1px solid $placeholderGray;
  padding: $spacingXXS;
  img {
    width: 100%;
    height: 100%;
  }
}
