@import "../../../../styles/global.main.scss";

.auto-scroll-content {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: $generalColorWhite;
    border-radius: 8px;
    border: 1px solid $placeholderGray;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $borderColorLightGray;
    border-radius: 4px;
    border: 3px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }

  width: 100%;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
