@import './variables/spacing';

.page {
  &--loading {
    margin: $spacingM auto;
  }
}

// Screen reader only class
/* stylelint-disable unit-whitelist */
.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
}
/* stylelint-enable unit-whitelist */
