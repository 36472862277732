@import "../../../../styles/global.main.scss";

.appointment-detail {
  .client-detail {
    display: grid;
    grid-template-columns: 95px auto;
    img {
      width: 50px;
    }
    span {
      display: block;
    }
  }
  .appointment {
    padding: $spacingXXS $spacingS;
    div {
      display: grid;
      grid-template-columns: 90px auto;
      margin-top: $spacingXXXS;
      span {
        font-size: $fontSize16;
        font-weight: 600;
      }
    }
  }
  .attachments {
    ul {
      li {
        display: grid;
        grid-template-columns: auto 10% 10%;
        margin-bottom: $spacingXXS;
        button {
          float: left;
        }
      }
    }
  }

  // Tooltip
.tooltip {
  background-color: black;
  color: white;
  padding: 8px;
  border-radius: 4px;
  position: absolute; /* Adjust according to your layout */
  z-index: 10; /* Ensure it appears above other elements */
}

}

// Tooltip
.tooltip {
  background-color: black;
  color: white;
  padding: 8px;
  border-radius: 4px;
  position: absolute; /* Adjust according to your layout */
  z-index: 10; /* Ensure it appears above other elements */
}
