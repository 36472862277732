@import "../../../../styles/global.main.scss";
.schedule {
  ul {
    li {
      max-width: 330px;
      span {
        width: 180px;
        line-height: $spacingS;
        display: inline-block;
        font-weight: bold;
      }
      small {
        font-size: $fontSizeSmall;
        span {
          font-weight: 500;
        }
      }
    }
  }
}
@media (max-width: 1510px) {
  .schedule {
    ul {
      li {
        span {
          width: 120px;
        }
      }
    }
  }
}
