@import "../../../styles/global.main.scss";

.calendar-loader {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $generalColorWhite;
  border-radius: 8px;
  padding-top: 15px;
  @mixin loading-skeleton(
    $objects: (),
    $element-height: 150px,
    $color: $fontColorLightGray,
    $background: $generalColorWhite,
    $highlight-height: $element-height
  ) {
    $positions: ();
    $gradients: ();
    $sizes: ();
    $rnd: unique-id();

    @each $obj in $objects {
      $positions: append(
        $positions,
        #{map-get($obj, "x") map-get($obj, "y")},
        "comma"
      );
      $gradients: append(
        $gradients,
        linear-gradient($color #{map-get($obj, "height")}, transparent 0),
        "comma"
      );
      $sizes: append(
        $sizes,
        #{map-get($obj, "width") $element-height},
        "comma"
      );
    }

    @at-root {
      @keyframes loading-skeleton-animation-#{$rnd} {
        to {
          background-position: calc(100% + 50px) 0, #{$positions};
        }
      }
    }

    background-image: linear-gradient(
        100deg,
        rgba($background, 0),
        rgba($background, 0.5) 50%,
        rgba($background, 0) 80%
      ),
      #{$gradients};
    background-size: 250px $highlight-height, #{$sizes};
    background-position: 0 0, #{$positions};
    animation: loading-skeleton-animation-#{$rnd} 1s infinite;
  }

  .box {
    height: 100%;
    width: 100%;
    background-repeat: repeat-y;

    @include loading-skeleton(
      (
        (x: 1%, y: 0, width: calc(100% / 7.2), height: 160px),
        (x: 17.5%, y: 0, width: calc(100% / 7.2), height: 160px),
        (x: 34%, y: 0, width: calc(100% / 7.2), height: 160px),
        (x: 50.3%, y: 0, width: calc(100% / 7.3), height: 160px),
        (x: 66.5%, y: 0, width: calc(100% / 7.3), height: 160px),
        (x: 82.7%, y: 0, width: calc(100% / 7.3), height: 160px),
        (x: 98.9%, y: 0, width: calc(100% / 7.3), height: 160px)
      ),
      164px,
      #eee,
      $generalColorWhite
    );
  }
}
