@import "../../../../../styles/global.main.scss";

.payment-methods {
  .title-lg {
    font-size: $fontSize20;
    margin-bottom: $spacingS;
    small {
      button {
        color: $primaryBlue;
        font-weight: 600;
        margin-left: $spacingM;
        font-size: $fontBaseSize;
        background: transparent;
      }
    }
  }
  .title-md {
    font-size: $fontSize16;
  }
  p {
    span {
      line-height: $spacingM;
    }
  }
  .btn-default {
    background: transparent;
    color: $primaryBlue;
    padding: 0;
  }
}
