@import "../global.main.scss";
.sign-in {
  .toggle {
    position: relative;
  }
  .left,
  .right {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: $fontColorBlack;
    .box {
      max-width: 435px;
      min-width: 435px;
    }
    .shadow {
      padding: 20px !important;
      border-radius: 10px;
      box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.1);
      h2 {
        margin-bottom: 3rem !important;
      }
    }
    .margin-top {
      margin-top: 100px;
    }
    .change-page-wrapper {
      display: flex;
      align-items: center;
      position: absolute;
      top: 28px;
      right: 46px;
      a {
        margin-left: 1rem;
        border: 0px solid transparent !important;
        &:hover {
          border: 1px solid $brandColorPrimary !important;
        }
      }
    }
  }
  .left {
    background-color: $backgroundColorGray;
    .brand,
    article,
    p {
      display: block;
    }
    .brand {
      margin-top: $spacingXXXL;
      margin-bottom: $spacingXXXL;
      color: $brandColorPrimary;
      display: block;
      width: 100%;
    }
    button {
      margin-bottom: $spacingS;
    }
    article {
      height: 126px;
      margin-bottom: $spacingM;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
    }
    p {
      margin-top: $spacingXXXL;
    }
  }
  .right {
    h2 {
      font-size: 30px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.37;
      letter-spacing: normal;
      text-align: left;
      color: $fontColorBlack;
      display: block;
      width: 100%;
      small {
        display: block;
        font-size: $fontSize20;
        color: $fontPrimaryColor;
        margin-top: $spacingXXS;
        span {
          color: $brandColorPrimary;
          text-transform: capitalize;
        }
        button {
          background-color: transparent;
          border: 0;
        }
      }
    }
    .verify-loader {
      width: 100%;
      max-width: 435px;
      height: 300px;
      padding: 2rem 0;
      box-sizing: border-box;
      .card-loader {
        width: 100%;
        height: 300px;
      }
      .card-ani {
        background-image: linear-gradient(
          90deg,
          #eee 0px,
          #fff 40px,
          #eee 80px
        );
        background-size: 300%;
        background-position: 100% 0;
        border-radius: inherit;
        animation: wave 1.5s infinite;
      }
      .title {
        height: 32px;
        margin-bottom: 6rem;
        border-radius: 4px;
      }

      .description {
        height: 20px;
        margin-bottom: 9rem;
        border-radius: 4px;
      }
      .button {
        height: 46px;
        border-radius: 46px;
      }

      @keyframes wave {
        to {
          background-position: -100% 0;
        }
      }
    }
    .switch {
      display: inline-block;
      position: relative;
      width: 435px;
      height: 45px;
      border-radius: 26px;
      vertical-align: middle;
      cursor: pointer;
      background-color: #eff6fe;
    }

    .switch::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 218px;
      height: 45px;
      background: $brandColorPrimary;
      border-radius: 26px;
      transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1),
        background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
        box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    }
    .switch:active::before {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28),
        0 0 0 20px rgba(128, 128, 128, 0.1);
    }
    input.checked + .switch::before {
      left: 218px;
      color: #fff;
    }
    input.checked + .switch:active::before {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28),
        0 0 0 20px rgba(0, 150, 136, 0.2);
    }

    .label-size {
      font-family: $fontPrimary;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
    }
    .client {
      position: absolute;
      left: 89px;
      top: 13px;
      cursor: pointer;
    }
    .business {
      position: absolute;
      right: 89px;
      top: 13px;
      cursor: pointer;
      width: auto;
    }
    .business.active,
    .client.active {
      color: #fff;
    }

    @media (max-width: 991px) {
      .left,
      .right {
        .box {
          max-width: 320px;
          min-width: 100%;
          padding: 0 $spacingS;
        }
      }
    }

    @media (max-width: 767px) {
      .left,
      .right {
        width: 100%;
        min-height: auto;
        padding: $spacingM 0;
        .box {
          max-width: 320px;
          min-width: 100%;
          padding: 0 $spacingS;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .left,
    .right {
      min-height: auto;
      padding: $spacingM;
      .box {
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
}
