@import "../../../../../styles/global.main.scss";
.book-an-appointment {
  .booking-header {
    padding-top: $spacingXS;
    display: grid;
    grid-template-columns: auto 184px;
    span {
      font-size: $fontSize16;
      font-weight: 700;
    }
  }
  .booking-body {
    display: grid;
    grid-template-columns: 300px auto;
    padding-top: $spacingXS;
    .calendar {
      border: 1px solid $placeholderGray;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      height: 318px;
      overflow: hidden;
    }

    .slots {
      margin-left: $spacingXS;
      label.font-weight-bold {
        font-weight: bold;
      }
      .slot-list {
        margin-top: $spacingXS;
        border: 1px solid $placeholderGray;
        border-radius: 8px;
        padding: $spacingXXXS;
        padding-left: 0;
      }
    }
  }
  .booking-footer {
    margin-top: $spacingXS;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn-blue-gradient {
      margin-top: 0;
    }
  }
}
