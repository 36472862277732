@import "../../../../styles/global.main.scss";
.cities-partial {
  list-style: none;
  list-style-image: none;
  padding-top: $spacingXS;
  display: block;
  li {
    display: block;
    padding: $spacingXXS;
    font-size: $fontBaseSize;
    border-bottom: 1px solid #efefef;
    span {
      float: right;
      font-size: 18px;
      cursor: pointer;
    }
  }
}
