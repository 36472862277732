@import "../../../../styles/global.main.scss";

.contact-profile-card {
  .contact-profile-picture {
    h2 {
      position: relative;
      button {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    figure {
      width: 210px;
      height: 204px;
      border: 1px solid $primaryBlue !important;
      border-radius: 37px;
      overflow: hidden;
      margin: 3rem auto;
      img {
        max-height: 100%;
        margin: 0 auto;
      }
    }
  }
  .group-section {
    padding: $spacingXS $spacingL;
    h4 {
      display: block;
      padding-bottom: $spacingXXS;
      span {
        float: right;
        font-weight: 700;
      }
    }
    .groups {
      li {
        display: inline-block;
        margin: $spacingXXXS;
        background: $groupLabelBg;
        padding: $spacingXXS $spacingS $spacingXXS $spacingXS;
        color: $generalColorWhite;
        border-radius: 10px;
        min-width: 112px;
        position: relative;
        button {
          position: absolute;
          top: 0.5rem;
          right: 0.5rem;
          background: transparent;
          color: $generalColorWhite;
          font-size: $fontSize20;
        }
      }
    }
    .action-buttons {
      margin-top: $spacingS;
    }
    .created {
      span {
        color: $fontPrimaryColor;
        line-height: $spacingXS;
        font-weight: 600;
        display: block;
        &:last-child {
          font-weight: 500;
          color: $fontColorBlack;
        }
      }
    }
  }
  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
