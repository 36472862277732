@import "../../../../styles/global.main.scss";
.table-responsive {
  padding: $spacingXXXS;
  overflow-y: hidden;
  padding-bottom: 100px;
  .custom-table {
    width: 100%;
    thead {
      tr {
        color: $primaryBlue;
        font-weight: 600;
        height: 40px;
        padding: 1rem;
        th,
        td {
          padding: $spacingXXS $spacingXXS;
        }
        th:first-child {
          padding-left: 1rem;
        }
        th:last-child {
          text-align: right;
          padding-right: 2.5rem;
          width: 160px;
        }
        th {
          span {
            margin-right: 10px;
          }
        }
      }
      &:first-child {
        tr {
          .no-space {
            padding-left: 0;
          }
        }
      }
    }
    tbody {
      border-collapse: separate;
      border-spacing: 15px;
      box-shadow: 0 0 0 1px $placeholderGray;
      border-radius: 8px;
      tr {
        border-top: 1px solid $placeholderGray;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        padding: 1rem;

        &:first-child {
          border-top: 1px solid transparent;
        }
        &:last-child {
        }
        td:last-child {
          text-align: right;
          padding-right: 1.5rem;
          width: 180px;
        }
        td {
          input[type="checkbox"] {
            margin-left: $spacingXXS;
          }
        }
        &.active {
          background: rgba(167, 190, 255, 0.3);
          border: 1px solid $primaryBlue !important;
          border-top: 1px solid $primaryBlue !important;
        }
      }
      th,
      td {
        padding: $spacingXXS $spacingXXS;
      }
    }
    tfoot {
      tr {
        th {
          padding: $spacingXXS $spacingXXXS;
        }
      }
    }
    .total-page {
      float: left;
      select {
        margin: $spacingXXS $spacingXXXS;
        border: 1px solid $placeholderGray;
        padding: $spacingXXXS;
        border-radius: 8px;
      }
    }
    .custom-pagination {
      float: right;
      li {
        display: inline-block;
        button {
          margin: $spacingXXXS;
          border: 1px solid $placeholderGray !important;
          border-radius: 8px;
          padding: 15px 10px;
          background-color: $generalColorWhite;
          font-weight: 600;
          font-size: $fontBaseSize;
          &.active {
            border-color: $primaryBlue !important;
            color: $primaryBlue;
          }
        }
      }
    }
    .avatar-in-table {
      display: flex;
      align-items: center;
      width: 100%;
      div.members {
        min-width: 80px;
        div {
          display: inline-block;
          margin-right: -$spacingXXXS;
          img {
            width: 20px;
            height: 20px;
            border-radius: 100%;
            float: left;
            border: 1px solid $placeholderGray;
          }
        }
      }
      div.member {
        div {
          display: inline-block;
          margin-right: -$spacingXXXS;
          img {
            width: 20px;
            height: 20px;
            border-radius: 100%;
            float: left;
            border: 1px solid $placeholderGray;
          }
        }
      }
      div.single-member {
        display: inline-block;
        margin-right: -$spacingXXXS;
      
        img {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          float: left;
          border: 1px solid $placeholderGray;
        }
      
        &.business {
          border: 4px solid #1b84e7; // blue
          border-radius: 100%;
        }
      
        &.staff {
          border: 4px solid #28a745; // green
          border-radius: 100%;
        }
      
        &.client {
          border: 4px solid #ffc107; // yellow
          border-radius: 100%;
        }

        &.disabled {
          opacity: 0.5;
        }
      }
      
      span {
        float: right;
        padding-left: $spacingM;
        text-align: left;
      }
    }
  }
}
