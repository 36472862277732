@import '../../../styles/variables/_spacing.scss';
@import '../../../styles/variables/_colors.scss';
@import '../../../styles/variables/_fonts.scss';

.custom-pagination {
  float: right;

  li {
    display: inline-block;

    button {
      margin: $spacingXXXS;
      border: 1px solid $placeholderGray !important;
      border-radius: 8px;
      padding: 15px 10px;
      background-color: $generalColorWhite;
      font-weight: 600;
      font-size: $fontBaseSize;

      &.active {
        border-color: $primaryBlue !important;
        color: $primaryBlue;
      }
    }
  }
}
