@import "../../../../styles/global.main.scss";

.switch-button {
  label {
    font-size: 1.2rem;
    cursor: pointer;
    display: block;
    margin: 0 1em;
    > input {
      
    }

    span {
      color: $primaryBlue !important ;
      font-size: $fontBaseSize;
      font-weight: bold;
      padding: 0 $spacingXS;
    }

    i {
      display: inline-block;
      width: 50px;
      height: 24px;
      border-radius: 20px;
      vertical-align: middle;
      transition: 0.25s 0.09s;
      position: relative;
      background: $placeholderGray;
      &:after {
        content: " ";
        display: block;
        width: 23px;
        height: 23px;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        background: $generalColorWhite;
        border: 1px solid $primaryBlue;
        position: absolute;
        transition: 0.15s;
      }
    }
    > input:checked + i {
      background: $primaryBlue;
    }
    > input:checked + i:after {
      transform: translateX(26px);
    }
  }
  &.small {
    label {
      i {
        width: 28px;
        height: 13px;
        &:after {
          content: " ";
          width: 13px;
          height: 13px;
        }
      }
      > input:checked + i:after {
        transform: translateX(16px);
      }
    }
  }

  &.disabled {
    label {
      cursor: not-allowed;
      opacity: 0.5;
      i {
        background: $placeholderGray;
        opacity: 0.5;
        &:after {
          background: $placeholderGray;
          border: 1px solid $placeholderGray;
          opacity: 0.5;
        }
      }
    }
  }
}
