@import "../../../../styles/global.main.scss";

.view-edit-contact {
  .contact-left {
    display: inline-block;
    width: 27%;
    overflow: hidden;
  }
  .contact-right {
    display: inline-block;
    width: 73%;
  }
  @media screen and (max-width: 1400px) {
    .contact-left {
      display: inline-block;
      width: 30%;
    }
    .contact-right {
      display: inline-block;
      width: 70%;
    }
  }

  @media screen and (max-width: 1180px) {
    .contact-left {
      display: inline-block;
      width: 40%;
    }
    .contact-right {
      display: inline-block;
      width: 60%;
    }
  }

  @media screen and (max-width: 991px) {
    .contact-left {
      display: inline-block;
      width: 100%;
    }
    .contact-right {
      display: inline-block;
      width: 100%;
    }
  }
}
