@import "../../../../styles/global.main.scss";

.data-loader {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $generalColorWhite;
  border-radius: 8px;
  .titles {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    span {
      width: calc(100% / 6);
      height: 40px;
      background-color: $fontColorLightGray;
      border-radius: 4px;
      float: left;
      margin-right: 10px;
    }
  }
  .titles-lg {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    span {
      width: calc(100% / 4);
      height: 75px;
      background-color: #eee;
      border-radius: 4px;
      float: left;
      margin-right: 10px;
    }
  }
  @mixin loading-skeleton(
    $objects: (),
    $element-height: 150px,
    $color: $fontColorLightGray,
    $background: $generalColorWhite,
    $highlight-height: $element-height
  ) {
    $positions: ();
    $gradients: ();
    $sizes: ();
    $rnd: unique-id();

    @each $obj in $objects {
      $positions: append(
        $positions,
        #{map-get($obj, "x") map-get($obj, "y")},
        "comma"
      );
      $gradients: append(
        $gradients,
        linear-gradient($color #{map-get($obj, "height")}, transparent 0),
        "comma"
      );
      $sizes: append(
        $sizes,
        #{map-get($obj, "width") $element-height},
        "comma"
      );
    }

    @at-root {
      @keyframes loading-skeleton-animation-#{$rnd} {
        to {
          background-position: calc(100% + 50px) 0, #{$positions};
        }
      }
    }

    background-image: linear-gradient(
        100deg,
        rgba($background, 0),
        rgba($background, 0.5) 50%,
        rgba($background, 0) 80%
      ),
      #{$gradients};
    background-size: 250px $highlight-height, #{$sizes};
    background-position: 0 0, #{$positions};
    animation: loading-skeleton-animation-#{$rnd} 1s infinite;
  }

  .box {
    height: 80%;
    width: 100%;
    background-repeat: repeat-y;

    @include loading-skeleton(
      (
        (x: 10px, y: 60px, width: 98%, height: 12px),
        (x: 0, y: 130px, width: 100%, height: 0px)
      ),
      44px,
      $fontColorLightGray,
      $generalColorWhite
    );
  }
}
.page-loader {
  height: 100vh;
  display: flex;
  .sidebar-left {
    height: 100%;
    width: 200px;
    background-color: $backgroundColorSection;
    padding: 30px 15px;
    .profile {
      display: flex;
      .name {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-1 {
          height: 30px;
          width: 180px;
          background-color: $backgroundColorLightSilver;
          margin-bottom: 4rem;
        }
      }
    }
    .nav-group {
      margin: 10px 0 10px 0px;
      .label {
        height: 12px;
        background-color: $backgroundColorLightSilver;
        border-radius: 3px;
        width: 65%;
      }
      .group-container {
        margin-top: 22px;
        .link {
          height: 10px;
          background-color: $backgroundColorLightSilver;
          border-radius: 3px;
          margin: 16px 0px;
        }
      }
    }
  }
  .dashboard-container {
    padding: 0px 20px;
    width: calc(100vw - 200px);
    max-width: calc(100vw - 200px);
    overflow: hidden;
    .cards {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      [class*="card"] {
        background-color: $backgroundColorSection;
        border-radius: 4px;
      }
      .card {
        height: 40vh;
        margin: 10px 5px 0px 0px;
        flex: 1 1 90px !important;
        border: 0;
        &-xl {
          height: 5vh;
          width: 100%;
        }
        &-m {
          margin-top: 10px;
          height: 40vh;
          width: 50%;
          width: calc(50% - 7px);

          &:first-child {
            margin-right: 3px;
          }
          &:last-child {
            margin-left: 3px;
          }
        }
      }
    }
  }
}

.shade {
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    display: block;
    z-index: 5;
    height: 100%;
    width: 0;
    top: 6%;
    animation: shades 1600ms cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
    box-shadow: 0 2px 15px 9px rgba(255, 255, 255, 0.4);
  }
}

@keyframes shades {
  0% {
    left: 0%;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}
