@import "../../../../styles/global.main.scss";
span.last-update {
  max-width: 300px;
  display: inline-block;
  padding: 0 3rem 0 0;
  text-align: right;
  font-size: $fontBaseSize;
  color: $fontPrimaryColor;
  small {
    display: grid;
    grid-template-columns: auto auto;
    color: $fontColorBlack;
    font-size: $fontBaseSize;
    margin-top: $spacingXXXS;
    span {
      margin-left: $spacingXXS;
      &:first-child {
        border-right: 1px solid #ddd;
        padding-right: $spacingXXS;
      }
    }
  }
}
