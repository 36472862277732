@import "../../../../styles/global.main.scss";

.share-modal {
  .btn-export {
    position: absolute;
    bottom: 32px;
  }
}

.form-container {
  display: flex;
  justify-content: space-between;
  width: 900px;
}

.form-column {
  width: 440px;
  padding: 0 10px;
}

@media (max-width: 960px) {
  .form-container {
    flex-direction: column;
    align-items: center;
  }
  .form-column {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 20px;
  }
}