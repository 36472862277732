@import "../../../../styles/global.main.scss";
.view-switch {
  @mixin button-features() {
    border: 1px solid $placeholderGray !important;
    background-color: transparent;
    width: 40px;
    height: 40px;
  }
  .grid-view {
    @include button-features();
    border-right: 0 !important;
    border-radius: 8px 0 0 8px;
    background: url(../../../../assets/icons/icon-grid-view.svg) no-repeat
      center center;
    &.active {
      background: url(../../../../assets/icons/icon-grid-view-active.svg)
        no-repeat center center;
      border-color: $primaryBlue !important;
      border-right: 1px solid $primaryBlue !important;
    }
  }
  .list-view {
    @include button-features();
    border-radius: 0 8px 8px 0;
    background: url(../../../../assets/icons/icon-list-view.svg) no-repeat
      center center;
    &.active {
      background: url(../../../../assets/icons/icon-list-view-active.svg)
        no-repeat center center;
      border-color: $primaryBlue !important;
    }
  }
}
