@import "../../../../../../styles/global.main.scss";

.holidays {
  display: grid;
  grid-template-columns: 90px auto 70px;
  border: 1px solid $placeholderGray;
  padding: $spacingXS;
  border-radius: 8px;
  align-items: center;
  div {
    font-size: $fontSize16;
    font-weight: bold;
    small {
      display: grid;
      grid-template-columns: 120px auto;
      justify-content: flex-start;
      font-weight: normal;
      margin-top: $spacingXXS;
    }
    &:last-child {
      text-align: right;
    }
  }
}
