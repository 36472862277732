@import "../../../../../../../styles/global.main.scss";
.daily-view {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 318px;
  button.prev {
    width: 40px;
    height: 18px;
    background: transparent
      url(../../../../../../../assets/images/arrow-left.svg) no-repeat left
      center;
    opacity: 0.5;
  }
  button.next {
    width: 40px;
    height: 18px;
    background: transparent
      url(../../../../../../../assets/images/icon-arrow-right.svg) no-repeat
      right center;
    opacity: 0.5;
  }
  span {
    font-size: $fontSize24;
    color: $primaryBlue;
    font-weight: 700;
  }
}
