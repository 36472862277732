@import "../../../../styles/global.main.scss";

.profile-card {
  padding: $spacingXS;
  box-shadow: 0 3px 6px 0 rgba(167, 190, 255, 0.3);
  border: solid 1px $fontColorLightGray;
  background-color: $generalColorWhite;
  max-width: 100%;
  margin-top: $spacingXS;
  transition: all 0.5s;
  position: relative;
  border-radius: 0.5rem;
  height: 100%;
  .avarage {
    position: absolute;
    bottom: 30%;
    right: 3%;
    z-index: 2;
    font-size: 120px;
    font-weight: bold;
    color: $fontColorBlack;
    line-height: 1;
    small {
      display: block;
      font-size: 25px;
      font-weight: bold;
      text-align: right;
    }
  }
  .image {
    position: absolute;
    right: -100%;
    top: 20%;
  }
  .title {
    position: relative;
    font-size: $fontSize16;
    font-weight: bold;
    color: $primaryBlue;
    small {
      font-size: $fontBaseSize;
      color: $fontColorBlack;
      display: block;
      padding-top: $spacingXS;
    }
    .settings {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .tasks {
    display: grid;
    grid-template-columns: 7% 93%;
    margin-right: $spacingXXS;

    > div {
      span {
        font-size: $fontSize16;
        color: $primaryBlue;
        display: block;
        button {
          background: transparent;
          color: $primaryBlue;
        }
      }
      small {
        font-size: 0.9rem;
      }
    }
  }
  @media (max-width: 1600px) {
    .image {
      width: 75%;
    }
  }
  @media (max-width: 1440px) {
    .tasks {
      display: grid;
      grid-template-columns: 15% 85%;
      margin-right: $spacingXXS;
    }
    .avarage {
      font-size: 80px;
    }
  }
  @media (max-width: 1280px) {
    transition: all 0.5s;
    .image {
      display: none;
    }
  }
  @media (max-width: 767px) {
    .avarage {
      position: absolute;
      bottom: 1%;
      right: 3%;
      z-index: 2;
      font-size: calc(100% + 4rem);
      font-weight: bold;
      color: $fontColorBlack;
      line-height: 1;

      small {
        display: block;
        font-size: 1.3rem;
        font-weight: bold;
      }
    }
    .tasks {
      display: grid;
      grid-template-columns: 15% 85%;
      margin-right: $spacingXXS;

      > div {
        span {
          font-size: $fontSize16;
          color: $primaryBlue;
          display: block;
        }
        small {
          font-size: 0.9rem;
        }
      }
    }
  }
}
