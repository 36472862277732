.applied-service {
  .wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  @media screen and (max-width: 1024px) {
    .wrapper {
      flex-wrap: wrap;
    }
  }
}
