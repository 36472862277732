@import "../../../../styles/global.main.scss";

.right-toggler {
  position: fixed;
  top: 0;
  right: -580px;
  width: 560px;
  height: 100%;
  background: $backgroundColorGray;
  border-left: $placeholderGray;
  z-index: 99;
  box-shadow: -4px 0 6px 0 rgba(167, 190, 255, 0.35);
  transition: all 0.5s;
  padding-bottom: $spacing6XL;
  &.active {
    right: 0;
    transition: 0.5s;
  }
  .toggler-title {
    padding-top: $spacingXS;
    padding-bottom: $spacingXS;
    background: $generalColorWhite;
    margin-bottom: 0;
    position: relative;
    span {
      font-weight: bold;
      text-transform: uppercase;
      font-size: $fontSize25;
      padding-left: $spacingS;
      position: relative;
      &.arrow-active {
        padding-left: $spacingXXL;
        background: url("../../../../assets/icons/arrow-big-left.png") no-repeat
          left 30px center;
      }
    }
    .toggle-close {
      font-size: $fontSize30;
      color: $fontPrimaryColor;
      position: absolute;
      right: $spacingS;
      top: 4rem;
      padding: 0;
      margin: 0;
      line-height: 0;
    }
  }
  .body {
    overflow: auto;
    height: 100%;
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: $generalColorWhite;
      border-radius: 8px;
      border: 1px solid $placeholderGray;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $borderColorLightGray;
      border-radius: 4px;
      border: 3px solid transparent;
      background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #a8bbbf;
    }
  }
  .toggle-footer {
    padding: $spacingXS;
    background: $backgroundColorGray;
    box-shadow: 0 -2px 0 0 $placeholderGray;
    width: 100%;
    bottom: 0;
    z-index: 10;
    position: absolute;
  }
}
