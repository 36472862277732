@import "../../../../styles/global.main.scss";
.right-sidebar {
  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dee2e6;
    border-radius: 20px;
    border: 7px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
  position: relative;
  padding: $spacingXXS 0;
  background: $generalColorWhite;
  width: 100%;
  overflow: hidden;
  h4 {
    padding: $spacingXXS $spacingXS;
    font-size: $fontSize20;
    font-weight: bold;
    margin: 0;
    .btn-cancel {
      position: absolute;
      right: 10%;
      top: 0px;
      background: transparent;
      border: 0;
      color: $fontColorBlack;
      font-size: $fontSize30;
      font-weight: bold;
      &:focus {
        outline: none;
        border: 0;
      }
    }
  }

  .form-group {
    padding: $spacingXXS $spacingXS;
    max-width: 100%;
    input[type="text"] {
      padding-left: $spacingM;
      background-image: url(../../../../assets/icons/icon-search-blue-23x23.png);
      background-repeat: no-repeat;
      background-position: 4% center;
    }
  }

  .widget-list {
    li {
      display: grid;
      grid-template-columns: 30% 70%;
      padding: $spacingXXS $spacingXS;
      border-bottom: 1px solid $placeholderGray;
      .image {
        grid-column: 1 / span 1;
        background-color: $generalColorWhite;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1rem;
        width: 90px;
        height: 90px;
        border: 1px solid $placeholderGray;
        img {
          width: 40px;
          height: 40px;
          overflow: hidden;
        }
      }
      .content {
        font-weight: 600;
        small {
          display: block;
          font-size: $fontBaseSize !important;
        }
      }
      &.active {
        background-color: $primaryBlue;
        .content {
          color: $generalColorWhite !important;
        }
      }
    }
  }

  footer {
    background-color: $generalColorWhite;
    border-top: 1px solid $placeholderGray;
    position: fixed;
    bottom: 0;
    width: 420px;
    box-shadow: 0 3px 6px 0 rgba(167, 190, 255, 0.4) inset;
    padding: $spacingXXS;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
}
