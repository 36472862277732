@import '../variables/fonts';
@import '../variables/spacing';

// Typographic mixins

@mixin title1 {
  margin-top: 0;
  margin-bottom: $spacingXXS;
  color: currentColor;
  font-weight: $fontWeightSemiBold;
  font-size: 3.7rem;
  line-height: 1.05;
}

@mixin title2 {
  margin-top: 0;
  margin-bottom: $spacingS;
  color: currentColor;
  font-weight: $fontWeightSemiBold;
  font-size: 3.2rem;
  line-height: 1.05;
}

@mixin title3 {
  margin-top: 0;
  margin-bottom: $spacingS;
  color: currentColor;
  font-weight: $fontWeightSemiBold;
  font-size: 2.7rem;
  line-height: 1.05;
}

@mixin title4 {
  margin-top: 0;
  margin-bottom: $spacingS;
  color: currentColor;
  font-weight: $fontWeightSemiBold;
  font-size: 2.2rem;
  line-height: 1.05;
}

@mixin headline {
  margin-top: 0;
  margin-bottom: $spacingS;
  color: currentColor;
  font-weight: $fontWeightMedium;
  font-size: 2rem;
  line-height: $fontBaseLineHeight;
}

@mixin bodyCopy {
  color: currentColor;
  font-weight: $fontWeightRegular;
  font-size: $fontBaseSize;
  line-height: $fontBaseLineHeight;
}
