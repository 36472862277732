@import "../../../../styles/global.main.scss";
.company-name-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .company-logo {
    border-radius: 100%;
    width: 32px;
    height: 32px;
    overflow: hidden;
    border: 1px solid $placeholderGray;
    margin-right: $spacingXS;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
