@import "../../../../../../styles/global.main.scss";
.ao-company-details {
  .details {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    figure {
      border-radius: 16px;
      border: 1px solid $placeholderGray;
      padding: $spacingXXS;
      width: 64px;
      height: 64px;
      margin: 0 $spacingXS 0 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
    div {
      h3 {
        padding: 0;
        margin: 0;
      }
      span {
        display: block;
        font-size: $fontSizeMed;
        margin-top: $spacingXXXS;
        line-height: 1.8;
      }
    }
  }
}
