@import "../../../../styles/global.main.scss";

.bussines-type {
  input[type="checkbox"] {
    display: none;
    position: absolute;
    + label {
      display: inline-block;
      padding: $spacingXXXS $spacingXS;
      margin: $spacingXXXS;
      background-color: $backgroundColorGray;
      border: 1px solid $borderColorLightGray;
      border-radius: 2rem;
      cursor: pointer;
      &.disabled {
        color: $fontPrimaryColor;
      }
    }
    &:checked + label {
      background-color: $primaryBlue;
      color: $generalColorWhite;
      border: 1px solid $primaryBlue;
    }
  }
}
