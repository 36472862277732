/**
 * @tokens Font Weight
 * @presenter FontWeight
 */

@import url("https://use.typekit.net/spi0hje.css");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap");

$fontPrimary: "Open Sans", sans-serif !important;
$fontSizeXSmall: 10px;
$fontSizeSmall: 11px;
$fontSizeMed: 12px;
$fontBaseSize: 13px;
$fontSize14: 14px;
$fontSize16: 1.6rem;
$fontSize18: 1.8rem;
$fontSize20: 20px;
$fontSize24: 24px;
$fontSize25: 25px;
$fontSize30: 30px;
$fontSize32: 32px;
$fontSize40: 40px;
$fontSize44: 44px;
$fontSize56: 56px;
$fontBaseLineHeight: 1.3;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemiBold: 600;
