@import "../global.main.scss";
.dashboard {
  .menu-mobile {
    display: none;
    position: fixed;
    right: 1rem;
    z-index: 8;
    top: 9%;
    width: 32px;
    height: 32px;
    border: 0;
    background: url(../../assets/images/menu.png) no-repeat;
    &:focus,
    &:active {
      outline: none;
      border: 0;
    }
  }
  .left {
    position: fixed;
    max-width: 250px;
    width: 250px;
    height: 100vh;
    background-color: $backgroundColorGray;
  }
  .right {
    width: 100%;
    margin-left: 260px;
    .remove-image {
      .image-progres {
        display: none;
      }
    }
  }
  @media (max-width: 1366px) {
    .col-sp {
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }
    .col-xl-6 {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
  @media screen and (max-width: 1280px) {
    .menu-mobile {
      display: block;
    }
    .left {
      left: -250px;
      transition: 0.5s;
      top: 5.8rem;
    }
    .show {
      left: 0;
      transition: 0.5s;
      z-index: 99999;
    }
    .right {
      padding: $spacingS;
      margin: 0;
    }
  }
  @media (max-width: 991px) {
    .col-sp {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
  @media (max-width: 767px) {
    .menu-mobile {
      top: 10%;
    }
  }
}
