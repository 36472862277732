@import "../.././../../styles/global.main.scss";

.modal-container {
  display: flex;
  overflow-y: hidden;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(167, 190, 255, 0.16);
  z-index: 1040;
  justify-content: center;
  align-items: center;
  .custom-modal {
    border: 1px solid $placeholderGray;
    padding: $spacingS;
    border-radius: 16px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background: $generalColorWhite;
    .modal-header {
      border: 0;
      padding: 0;
      font-size: $fontSize20;
      font-weight: 600;
      margin-bottom: $spacingS;
      position: relative;
      display: flex;
      align-items: center;
      .btn-modal-close {
        position: absolute;
        right: 1rem;
        top: -$spacingXXS;
        background: transparent;
        color: $placeholderGray;
        font-size: $fontSize30;
        padding: 0;
        margin: 0;
      }
      .btn-prev {
        background: transparent
          url(../../../../assets/icons/arrow-left-20x20.svg) no-repeat center
          center;
        width: 20px;
        height: 18px;
        border: 0;
        margin-right: $spacingXXXS;
        &:hover {
          background: transparent
            url(../../../../assets/icons/arrow-left-20x20-active.svg) no-repeat
            center center;
        }
      }
      .btn-next {
        background: transparent
          url(../../../../assets/icons/arrow-right-20x20.svg) no-repeat center
          center;
        width: 20px;
        height: 18px;
        border: 0;
        &:hover {
          background: transparent
            url(../../../../assets/icons/arrow-right-20x20-active.svg) no-repeat
            center center;
        }
      }
    }
    .modal-content {
      border: 0;
      p {
        text-align: left !important;
      }
    }
  }
}
