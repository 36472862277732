@import "../../../../../styles/global.main.scss";

@mixin page-box() {
    max-width:370px;
    margin:auto auto;
    height:100%;
    position:relative;
    padding-bottom:5rem;
}

.change-password-section {
    .row {
        .col-xl-6 {
            padding: $spacingXXXL 0;
        }
    }
    .box {
        @include page-box();
        .title{
            font-size:$fontSize20;
            font-weight: normal;
        }
        .form-element {
            margin-top:$spacingXS;
            label {
                
                    color:$primaryBlue;
                    font-weight: bold;
                    margin-bottom:$spacingXXS;
                
            }
        }
        button {
            position:absolute;
            bottom:0;
        }
    }
}
