@import "../../../../../../../styles/global.main.scss";

.calendar-monthly {
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }
  .react-calendar__navigation {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid $placeholderGray;
    width: 100%;
    .react-calendar__navigation__label {
      background: $generalColorWhite;
    }
    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__next-button {
      width: 53px;
      height: 53px;
      background: $generalColorWhite;
      font-size: 0;
    }
    .react-calendar__navigation__prev-button {
      background: transparent
        url(../../../../../../../assets/images/arrow-left.svg) no-repeat center
        center;
      opacity: 0.5;
      border-right: 1px solid $placeholderGray !important;
    }
    .react-calendar__navigation__next-button {
      background: transparent
        url(../../../../../../../assets/images/icon-arrow-right.svg) no-repeat
        center center;
      opacity: 0.5;
      border-left: 1px solid $placeholderGray !important;
    }
  }
  .react-calendar__month-view__weekdays {
    background: $paleGray;
    padding: $spacingXS $spacingXXS;
  }
  .react-calendar__month-view__days {
    .react-calendar__month-view__days__day {
      background: $generalColorWhite;
      width: 32px !important;
      height: 38px !important;
    }
    .react-calendar__tile--rangeBothEnds {
      background: $primaryBlue;
      border-radius: 100%;
      width: 32px !important;
      max-width: 32px !important;
      height: 32px !important;
      color: $generalColorWhite;
      margin-left: 0.7rem;
      margin-right: 0.36rem;
    }
  }
}
