@import "../../../../../../styles/global.main.scss";
.ao-consultant-details {
  .details {
    display: flex;
    justify-content: stretch;
    figure {
      margin: 0 $spacingXS 0 0;
      padding: 0;
      width: 36px;
      height: 36px;
      border-radius: 100%;
      border: 2px solid $primaryBlue;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    h3 {
      margin: 0;
      padding: 0;
      margin-bottom: $spacingXXXS;
      font-weight: 700;
    }
  }
  div {
    &:last-child {
      span {
        display: block;
        font-size: $fontSizeMed;
        line-height: 1.8;
      }
    }
  }
}
