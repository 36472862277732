@import "../../../../../../../styles/global.main.scss";

.select-slot-list {
  .slot {
    margin: 0;
    padding: 0;
    input[type="checkbox"] {
      display: none;
      + label {
        border: 1px solid transparent;
        border-bottom: 1px solid $placeholderGray;
        border-right: 1px solid $placeholderGray;
        width: 99%;
        display: flex;
        justify-content: flex-start;
        padding: 1.5rem;
        font-weight: normal;
        position: relative;
        margin-bottom: 0;
        span {
          &:first-child {
            min-width: 185px;
            font-weight: 600;
          }
        }
        .not-available {
          color: $semanticColorError;
        }
        &:after {
          position: absolute;
          content: "";
          width: 20px;
          height: 20px;
          border-radius: 4px;
          right: 1rem;
          top: 1.2rem;
          padding: $spacingXXXS;
          border: 1px solid $placeholderGray;
        }
      }
      &:checked {
        + label {
          background: $backgroundColorLightBlue;
          border: 1px solid $primaryBlue;
          color: $primaryBlue;

          &:after {
            border: 1px solid $primaryBlue;
            background: url(../../../../../../../assets/icons/square-blue-12x12.svg)
              no-repeat center center;
          }
        }
      }
    }
  }
}
