@import "../../../././../../styles/global.main.scss";

.service-details {
  .service-id {
    font-size: $fontSize20;
    color: $fontPrimaryColor;
    font-weight: 600;
  }
  .owner-details,
  .communication-channels,
  .summary,
  .schedule {
    margin-top: $spacingS;
    span {
      line-height: $spacingS;
    }
  }
  .summary {
    .wmde-markdown {
      margin: 0 !important;
    }
  }
  .owner-details {
    span {
      display: block;
    }
  }
  .communication-channels {
    span {
      display: block;
      max-width: 400px;
      span {
        float: right;
        margin-right: $spacingL;
      }
    }
  }

  h4 {
    font-size: $fontSize16;
    font-weight: bold;
    padding-bottom: $spacingXXXS;
  }
  .redirect-button {
    background-color: transparent;
    color: $primaryBlue;
    padding: 0;
  }
  .btn-text {
    text-align: left;
    width: 150px;
    button.btn-link {
      display: block;
      position: relative;
      text-align: left;
      margin-top: 0.7rem;
      &:after {
        position: absolute;
        content: "[Need Appointment First]";
        top: 17px;
        left: 0;
        font-size: 11px;
        width: 150px;
        color: gray;
      }
    }
    small {
      padding: 0;
      margin: 0;
    }
  }
}
