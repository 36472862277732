@import "../../../../../../styles/global.main.scss";

.ao-reminder {
  margin-top: $spacingM;

  .head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    label.text-primary-blue {
      padding-bottom: 0 !important;
    }
    .switch-button {
      margin-left: $spacingL;
    }
  }
  .body {
    margin-top: $spacingXS;
    form {
      display: flex;
      justify-content: space-between;

      div {
        span.reminder-title {
          font-weight: 700;
          display: block;
          margin-bottom: $spacingXXS;
        }
      }
      .reminder-trough {
        span {
          margin-bottom: $spacingXXS !important;
        }
      }

      .custom-checkbox {
        & + label {
          margin-top: $spacingXXS;
          padding-left: $spacingS;
          height: 24px;
          &:before {
            width: 20px;
            height: 20px;
          }
        }
        &:checked {
          & + label {
            &:before {
              border: 1px solid $primaryBlue;
              content: "";
              background-image: url(../../../../../../assets/icons/square-blue-12x12.svg);
              background-size: 65%;
              background-position: center;
            }
          }
        }
        &.disabled {
          & + label {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
