@import "../../../styles/global.main.scss";

.contacts-button-wrapper {
  position: absolute;
  padding-top: $spacingXXXS;
  top: 0;
  right: $spacingS;
  display: flex;
  justify-content: center;
}
