@import "../../styles/global.main.scss";
.invitations {
	position: relative;
	transition: all 0.5s;
	display: flex;
	.invitation-list-head {
		margin-bottom:$spacingXXS;
		.item {
			display: grid;
			grid-template-columns: 30% 25% 20% 25%;
			align-items: center;
			.attribute {
				color: $primaryBlue;
				font-weight: 600;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				&:last-child {
					text-align: center;
				}
			}
		}
	}
	.invitation-list {
		border-radius: 1rem;
		border: 1px solid $placeholderGray;
		.item {
			display: grid;
			grid-template-columns: 30% 25% 20% 25%;
			align-items: center;
			position: relative;
			border-bottom: 1px solid $placeholderGray;
			padding: $spacingXXXS $spacingXXXS $spacingXXXS $spacingXS;
			&:last-child {
				border-bottom: 0;
			}
			.attribute {
				text-overflow: ellipsis;
				&:last-child {
					display: flex;
					justify-content: flex-end;
					align-items: center;
				}
				.btn-link {
					font-size: 26px!important;
					color: $fontColorLightGray;
					text-decoration: none;
					position: relative;
				}
				.actions {
					position: relative;
					display: inline-block;
					.menu {
						position: absolute;
						top: 40px;
						right: 0;
						z-index: 99;
						background: $backgroundColorMain;
						border: 1px solid $borderColorLightGray;
						padding: $spacingXXS $spacingL $spacingXXS $spacingXXS;
						border-radius: .5rem;
						.menu-item {
							text-align: left;
							display: block;
							font-size: $fontBaseSize;
							margin-bottom: $spacingXXXS;
							color: $fontColorBlack;
							background-color: transparent;
							border: 0;
						}
					}
				}
			}
		}
	}
	@media screen and (max-width:1366px) {
		.invitation-list-head {
			.item {
				grid-template-columns: 25% 25% 25% 25%!important;
				.attribute {
					text-overflow: ellipsis;
					&:last-child {
						display: flex;
						justify-content: center;
					}
				}
			}
		}
		.invitation-list {
			.item {
				grid-template-columns: 25% 25% 25% 25%!important;
				&:last-child {
					border-bottom: 0;
				}
				.attribute {
					text-overflow: ellipsis;
					.btn-x-small {
						visibility: hidden;
						position:relative;
						&:before {
							content:'Accept';
							top:0;
							position: absolute;
							visibility: visible;
							color:$generalColorWhite!important;
							padding:$spacingXXXS $spacingXS;
							border-radius: 26px!important;
							font-size:13px!important;
							box-shadow: 0 4px 6px 0 rgba(167, 190, 255, 0.7);
							background-image: linear-gradient(to right, #1b84e7, #1515af 100%);
						}
					}
					&:last-child {
						display: flex;
						justify-content: flex-end!important;
						align-items: center;
					}
				}
			}
		}
	}
	@media (max-width:767px) {
		.invitation-list-head {
			display: none;
		}
		.invitation-list {
			margin-top: $spacingXS;
			.item {
				display: grid;
				grid-template-columns: auto!important;
				.attribute {
					.btn-x-small {
						visibility: visible;
						position:relative;
						&:before {
							content:'Accept';
							top:0;
							position: absolute;
							visibility: hidden;
							color:$generalColorWhite!important;
							padding:$spacingXXXS $spacingXS;
							border-radius: 26px!important;
							font-size:13px!important;
							box-shadow: 0 4px 6px 0 rgba(167, 190, 255, 0.7);
							background-image: linear-gradient(to right, #1b84e7, #1515af 100%);
						}
					}
					display: grid;
					grid-template-columns: minmax(9em, 50%) 1fr;
					border-bottom: 1px dotted #efefef;
					padding: $spacingXXS 0;
					&::before {
						content: attr(data-name);
					}
					&:last-child {
						display: flex;
						justify-content: space-between!important;
					}
				}
			}
		}
	}
}

@media (max-width:1024px) {
	.invitations {
		display: block!important;
	}
}