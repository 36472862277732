@import "../variables/spacing";
@import "../variables/colors";
.react-mde {
  border: 0;
  margin-top: $spacingXS;
  .mde-header {
    border: 1px solid $placeholderGray;
    border-radius: 26px 26px 0 0;
    background: $backgroundColorOffWhite;
    justify-content: stretch;
    .mde-tabs {
      float: left;
      flex-grow: 1;
      padding: $spacingXXS;
      button {
        font-weight: 600;
        border-radius: 30px;
        height: 30px;
        padding: 0 $spacingXS;
        margin-right: $spacingXXS;
        &.selected {
          background-color: $primaryBlue !important;
          border-radius: 26px !important;
          color: $generalColorWhite !important;
          box-shadow: 0 4px 6px 0 rgba(167, 190, 255, 0.7) !important;
        }
      }
    }
    .mde-header-group {
      float: right;
      margin-top: $spacingXXS !important;
    }
    .svg-icon {
      width: 1.3em;
      height: 1.3em;
    }
  }
  .mde-textarea-wrapper {
    border-radius: 0 0 26px 26px;
    border: 1px solid $placeholderGray;
    border-top: 1px solid $paleGray;
    padding: $spacingXXS;
    margin-bottom: $spacingXS;
  }
  .mde-preview {
    border: 1px solid $placeholderGray;
    margin-bottom: $spacingXS;
    border-radius: 0 0 26px 26px;
  }
}
