@import "../../../../../styles/global.main.scss";
.client-service-consultant {
  padding: $spacingXL;
  .consultant-box {
    width: 420px;
    height: 230px;
    border: 1px solid $primaryBlue;
    border-radius: 16px;
    box-shadow: 0 8px 12px 0 rgba(167, 190, 255, 0.2);
    position: relative;
    .avatar {
      position: absolute;
      top: -4.5rem;
      left: $spacingXS;
      width: 61px;
      height: 61px;
      border-radius: 100%;
      border: 2px solid $primaryBlue;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .box-top {
      display: grid;
      grid-template-columns: 210px auto;
      padding: $spacingXS $spacingXS;
      .consultant {
        display: block;
        font-size: $fontSize20;
        font-weight: 600;
        margin-bottom: $spacingXXXS;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      div {
        &:last-child {
          margin-left: $spacingXXXS;
          span {
            display: block;
            font-size: $fontSizeSmall;
            font-weight: 600;
            color: $fontColorClientLanguages;
            line-height: 25px;
          }
        }
      }
    }
    .box-body {
      padding: $spacingXXXS $spacingXS $spacingM $spacingXS;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 93px;
      max-height: 90px;
      overflow: hidden;
      div {
        &:first-child {
          padding: 0 $spacingXS 0 0;
        }
        p {
          line-height: 20px;
          margin-bottom: 0;
        }
      }
      .date-time {
        line-height: 24px;
        span {
          color: $primaryBlue;
          font-size: $fontSize16;
          font-weight: 600;
          margin: 0 $spacingXXXS;
        }
      }
    }
    .box-bottom {
      border-top: 1px solid $primaryBlue;
      height: 48px;
      overflow: hidden;
      .button-wrapper {
        display: grid;
        grid-template-columns: 50% 50%;
        button {
          height: 44px;
          padding: $spacingXXS 0;
          background: transparent !important;
          color: $primaryBlue;
          font-weight: 600;
          &:first-child {
            border-right: 1px solid $primaryBlue !important;
          }
        }
      }
    }
  }
}
