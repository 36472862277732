@import "../../../styles/variables/colors.scss";

.generic-page-container {
  margin-top: 20px !important;
  .page-left {
    width: 25%;
    display: inline-block;
    position: relative;
    order: 1;
  }
  .page-right {
    width: 75%;
    position: relative;
    order: 2;
  }
  @media screen and (max-width: 1366px) {
    .page-left {
      width: 20%;
    }
    .page-right {
      width: 80%;
      display: inline-block;
    }
  }
  @media screen and (max-width: 1199px) {
    .page-left {
      width: 100%;
    }
    .page-right {
      width: 100%;
      display: inline-block;
      order: 1;
    }
  }
}
